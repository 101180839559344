import { KPI } from "../../kpi";
import { Gems } from "../../gems";

const getDefaultState = () => {
  return {
    template: [],
  };
};

const state = {
  template: [],
};

const getters = {
  arrTemplate: (state) => state.template,
};

const actions = {
  async FetchTemplate({ dispatch }, args) {
    await KPI.CallBackEnd("get", "get-template", {
      params: { temp_id: args, cycle_id: localStorage.getItem("cycle") },
    }).then((response) => {
      dispatch("GetComp", { template: response.data })
    })
  },

  async GetComp({ commit }, args) {
    const template = args["template"]
    let entity = JSON.parse(localStorage.getItem("entity"))["value"]
    let comp, _360, resComp, res360

    await KPI.CallBackEnd("get", "get-operations", {
      params: {
        get: "third-party-applications-choice",
        cycle: localStorage.getItem("cycle"),
      },
    }).then((res) => {
      if (res.status == 200 && res.data.length > 0) {
        comp = res.data[0]["comp_id"]
        _360 = res.data[0]["360_id"]
      } else {
        comp = null
        _360 = null
      }
    })

    if (comp != null) {
      resComp = await Gems.CallWS({
        method: "post", app: 'comp', action: "get-cycles",
        body: { entity, id: comp },
      })
    }

    if (_360 != null) {
      res360 = await Gems.CallWS({
        method: "post", app: '360', action: "get-cycles",
        body: { entity, id: _360 },
      })
    }

    Object.values(template.SCOPES).forEach(async scope => {
      if (scope.sco_id == 4 && comp != null && resComp && typeof resComp.data != "string" && resComp.data?.length > 0) {
        resComp.data[0]["selected"] = true
        scope.INDICATORS = resComp.data
      }

      if (scope.sco_id == 5 && _360 != null && res360 && typeof res360.data != "string" && res360.data?.length > 0) {
        res360.data[0]["selected"] = true
        scope.INDICATORS = res360.data
      }
    })

    commit("storeTemplate", template);
  },


  ValidateWeights({ state }) {
    const templates = state.template
    let scopeSum = 0
    let error = 0

    Object.values(templates.SCOPES).forEach(scope => {
      if (scope.sco_id != 1)
        scopeSum += parseFloat(scope.sco_weight)

      if (scope.INDICATORS.length > 0 && scope.sco_id == 3) {
        let indicatorSum = 0
        scope.INDICATORS.forEach(indicator => indicatorSum += parseFloat(indicator.ind_weight))

        if (indicatorSum != 100) {
          Gems.Telegram(`A soma dos pesos dos indicadores no escopo ${scope.sco_desig} precisa ser igual a 100`, "tele-not-allowed")
          error++
        }
      }
    })

    if (scopeSum != 100 && error == 0) {
      Gems.Telegram("A soma dos pesos dos escopos precisa ser igual a 100", "tele-not-allowed")
      error++
    }

    if (error != 0)
      return false

    return true
  },

  async SaveTemplate({ dispatch }) {

    if (!await dispatch('ValidateWeights'))
      return

    const response = await KPI.CallBackEnd("post", "update-template", {
      arrTemplate: state.template,
    });

    if (response.status != 200 || response.error) {
      Gems.Telegram("Ocorreu um erro", "tele-not-allowed");
      return false
    }

    Gems.Telegram("Operação realizada com sucesso", "tele-success");
    return true
  },
};

const mutations = {
  storeTemplate: (state, template) => state.template = template,

  storeMetrics: (state, metrics) => state.metrics = metrics,

  updateScope: (_, params) => params.scope.sco_weight = params.weight,

  updateIndicator: (_, params) => Object.assign(params.indicator, params.new_indicator),

  addIndicator: function (state, indicator) {
    const scopes = Object.values(state.template.SCOPES)
    const sco_ind = scopes.findIndex((scope) => scope.sco_id == indicator.sco_id)

    scopes[sco_ind].INDICATORS.push(indicator)
  },

  removeIndicator: function (state, indicator) {
    const scopes = Object.values(state.template.SCOPES)
    const sco_ind = scopes.findIndex((scope) => scope.sco_id == indicator.sco_id)
    const indicator_ind = scopes[sco_ind].INDICATORS.findIndex((ind) => ind.ind_id == indicator.ind_id)

    scopes[sco_ind].INDICATORS.splice(indicator_ind, 1)
  },

  resetTemplate(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
