<template>
  <div>
    <div class="user-container">
      <input type="file" class="inputfile cursor-pointer" id="file" ref="file"
        @change="SelectFile" :disabled="(!admin && userId != null) || (!admin && user.can_upload == 0)" />
      <label class="cursor-pointer" for="file">
        <div class="photo">
          <img :src="src" v-if="src !== false && src !== null && error === false" @error="error = true" class="cover" />
          <i class="fas fa-user icon fa-5x" aria-hidden="true" v-else v-show="preview == false"></i>
          <img :src="src" v-show="preview == true && error === true" class="cover"/>
        </div>
      </label>

      <div class="div-splitted-inputs">
        <div class="div-input-left">
          <label>N° INTERNO</label>
          <input type="text" v-model="user.user_internal" :disabled="!admin"/>
        </div>

        <div class="div-input-right">
          <label>NOME PROFISSIONAL</label>
          <input type="text" v-model="user.professional_name" :disabled="!admin && userId != null"/>
        </div>
      </div>

      <div>
        <label>NOME COMPLETO</label>
        <input type="text" v-model="user.complete_name" :disabled="!admin" />
      </div>

      <div class="div-splitted-inputs">
        <div class="div-input-left">
          <label for="">GÉNERO</label>
          <select v-model="selectedGender" :disabled="(!admin || noGenders) && userId != null" class="cursor-pointer">
            <option
              v-for="gender in genders"
              :value="gender.gender_id"
              :key="gender.gender_id"
              :selected="gender.gender_id == user['gender_id']">
              {{ gender.gender_desc }}
            </option>
          </select>
        </div>

        <div class="div-input-right">
          <label for="">CATEGORIA</label>
          <select v-model="selectedCategory" :disabled="!admin" :class="{'cursor-pointer': admin}">
            <option
              v-for="category in categories"
              :value="category.id"
              :selected="
                  noJobs == true
                    ? category.id == 0
                    : category.id == user.category_id
                "
                :key="category.id"
                >
                {{ category.designation }}
            </option>
          </select>
        </div>
      </div>

      <div>
        <label for="">FUNÇÃO</label>
        <select v-model="selected" :disabled="!admin || noJobs" :class="{'cursor-pointer': admin}">
          <option
            v-for="job in jobs"
            :value="job.func_id"
            :selected="
              noJobs == true ? job.func_id == 0 : job.func_id == user.job_id
            "
            :key="job.func_id"
          >
            {{ job.func_desc }}
          </option>
        </select>
      </div>

      <div>
        <label>EMAIL</label>
        <input type="text" v-model="user.user_email" :disabled="!admin" />
      </div>

      <div class="div-teams">
        <label class="teams-label">EQUIPA(S)</label>
        <div id="teams-container">
          <div class="div-card default-app-color" v-if="user['teams']?.length == 0">[sem equipa]</div>
          <div v-else class="div-card default-app-color" v-for="(team, index) in user['teams']" :key="index">
            {{ team }}
          </div>
        </div>
      </div>

      <div>
        <label>USERNAME</label>
        <input type="text" v-model="user.username" :disabled="!admin" />
      </div>

      <div v-show="admin || userId == null" style="margin: 0">
        <div>
          <label>PASSWORD ATUAL</label>
          <input type="password" v-model="txtPass" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;" id="txtPass"/>
          <div id="eye-password">
            <i class="icon-eye cursor-pointer fas fa-eye" aria-hidden="true" ref="txtPass" @click="ChangeVisibility($refs.txtPass, 'txtPass')"></i>
          </div>
        </div>

        <div>
          <label>NOVA PASSWORD</label>
          <input type="password" v-model="txtNewPass" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;" ref="inputNewPassword"
            id="txtNewPass" @input="passwordStrength" @focus="showPasswordRulesPopup" @blur="closePasswordRulesPopup"/>
          <div id="eye-password">
            <i class="icon-eye cursor-pointer fas fa-eye" aria-hidden="true" ref="txtNewPass" @click="ChangeVisibility($refs.txtNewPass, 'txtNewPass')"></i>
          </div>
        </div>

        <div>
          <label>REPITA PASSWORD</label>
          <input type="password" v-model="txtNewPassConf" placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;" id="txtConfNewPass"
            @input="passwordStrength" @focus="showPasswordRulesPopup" @blur="closePasswordRulesPopup"/>
          <div id="eye-password">
            <i class="icon-eye cursor-pointer fas fa-eye" aria-hidden="true"
              ref="txtConfNewPass" @click="ChangeVisibility($refs.txtConfNewPass, 'txtConfNewPass')"></i>
          </div>
          
          <div id="pass-rules-container" class="pass-rule-container" v-show="showPasswordRulesCreation"></div>
        </div>
      </div>

      <div v-if="admin" class="div-check-container">
        <div class="div-check-container">
          <div class="div-active-check">
            <span>ATIVO</span>
            <input type="checkbox" id="active-check" class="active-check" :class="!user.can_activate && !user['active'] ? 'disable-elements' : ''" v-model="user['active']" />
          </div>

          <div class="div-admin-check">
            <span>ADMINISTRADOR</span>
            <input type="checkbox" id="admin-check" class="admin-check" v-model="user['admin']"/>
          </div>
        </div>
      </div>

      <div class="button" v-if="admin || userId == null">
        <button class="btn-action" @click="UpdateData(user.user_id)">GRAVAR</button>
      </div>
    </div>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "profile",
  props: {
    userId: [Number, String],
  },
  data() {
    return {
      user: [],
      user_id: Number,
      file: null,
      fileUser: "",
      src: String,
      error: false,
      selected: "",
      selectedCategory: "",
      selectedGender: "",
      jobs: [],
      categories: [],
      genders: [],
      name: null,
      txtPass: "",
      txtNewPass: "",
      txtNewPassConf: "",
      preview: false,
      admin: false,
      passRules: {},
      showPasswordRulesCreation: false,
      noJobs: false,
      noGenders: false,
      can_activate: true,
    };
  },
  methods: {
    showPasswordRulesPopup() {
      this.showPasswordRulesCreation = true;
    },

    closePasswordRulesPopup() {
      this.showPasswordRulesCreation = false;
    },

    passwordStrength() {
      this.passRules = Gems.GetPassRulesConfirmation(this.txtNewPass, this.txtNewPassConf, this.user.username)
    },

    SelectFile(e) {
      this.file = e.target.files[0];
      this.src = URL.createObjectURL(this.file);
      this.name = KPI.Cyrb53(`${Date.now()}`, 5);
      this.preview = true;
    },

    async UploadFile() {
      const formData = new FormData();
      formData.append("file", this.$refs.file.files[0]);
      formData.append("name", this.name);
      try {
        const response = await KPI.CallBackEnd(
          "post",
          "upload-image",
          formData
        );
        if (response.error) {
          Gems.Telegram(response.data, "tele-not-allowed");
        }
      } catch (err) {
        Gems.Telegram(err, "tele-not-allowed");
      }
      this.$refs.file.value = null;
      this.file = null;
    },

    CheckPassChange(id) {

      if (this.txtNewPass != '') {
        if (!this.txtNewPassConf) {
          Gems.Telegram("É necessário confirmar a nova password", "tele-not-allowed")
          return
        }

        if (!this.txtPass) {
          Gems.Telegram("É necessário confimar a password atual","tele-not-allowed")
          return
        }

        if (!Gems.IsPasswordValid(this.passRules)) {
          Gems.Telegram("Verifique as regras de password", "tele-not-allowed")
          this.showPasswordRulesCreation = true
          return
        }
      }

      this.UpdateUser(this.GetUserArr(id))
    },

    GetUserArr(id) {
      let user = [];
      user.push({
        internal: this.user.user_internal,
        full_name: this.user.complete_name,
        professional_name: this.user.professional_name,
        email: this.user.user_email,
        username: this.user.username,
        function: this.selected,
        category: this.selectedCategory,
        gender: this.selectedGender,
        photo_hash: this.name,
        id: id,
        active: this.user.active,
        admin: this.user.admin,
      });

      if (this.txtNewPass) {
        Object.assign(user[0], {
          pass_hash: this.txtPass,
          NewPass: this.txtNewPass,
          ConfirmNewPass: this.txtNewPassConf,
        });
      }
      return user;
    },

    async UpdateUser(user) {
      const response = await KPI.CallBackEnd("post", "update-user", {
        userArr: user,
        cycle: localStorage.getItem("cycle"),
      })
          if (response.status != 200) {
            Gems.Telegram("Erro ao gravar!", "tele-not-allowed");
            this.name = null
            return
          }

          if (response.data.error == false) {
            Gems.Telegram("Operação realizada com sucesso!", "tele-success")
            this.$emit("force-reload")
          } else {
            Gems.Telegram(response.data.message, "tele-not-allowed")
          }
          this.txtName = null
          this.txtEmail = null
          this.txtUserName = null
          this.txtNewPass = ''
          this.txtNewPassConf = ''
          this.txtPass = null
          document.getElementById('pass-rules-container').innerHTML = ''
          this.GetUser()

        this.name = null
    },

    async UpdateData(id) {
      if (this.file !== null) {
        this.UploadFile()
        this.src = this.name
      }

      this.CheckPassChange(id)
    },

    async GetUser() {
      const response = await KPI.CallBackEnd("get", "get-user", {
        params: { id: this.user_id, cycle: localStorage.getItem("cycle") },
      })

      this.user = response.data[0][0]
      this.selected = this.user.job_id
      this.selectedCategory = this.user.category_id
      this.selectedGender = this.user.gender_id == null ? 0 : this.user.gender_id
      this.src = this.user["src"]

      if (Number(this.user.admin)) 
        this.user.admin = true
      else 
        this.user.admin = false

      if (!this.user.can_activate && Number(this.user.active) == 0) 
        this.can_activate = false

      if (Number(this.user.active)) 
        this.user.active = true
      else
        this.user.active = false

      Gems.GeneratePassRules(document.getElementById('pass-rules-container'))
    },

    async GetJobs() {
      const response = await KPI.CallBackEnd("get", "get-operations", {
        params: { get: "functions", cycle: localStorage.getItem("cycle") },
      })

      if (response.data.length > 0) {
        response.data.unshift({ func_id: 0, func_desc: "", });
        this.jobs = response.data;
      } else {
        this.noJobs = true;
        this.selected = 0;
        this.jobs.push({ func_id: 0, func_desc: "", });
      }
    },

    async GetCategories() {
      const response = await KPI.CallBackEnd("get", "get-operations", {
        params: { get: "categories" },
      })

      if (response.data.length > 0) {
        response.data.unshift({ id: 0, designation: "",});
        this.categories = response.data;
      } else {
        this.categories.push({ id: 0, designation: "", });
      }
    },

    async GetGenders() {
      const response = await KPI.CallBackEnd("get", "get-operations", {
        params: { get: "genders" },
      })

      if (response.data.length > 0) {
        response.data.unshift({ gender_id: 0, gender_desc: "", });
        this.genders = response.data;
      } else {
        this.noGenders = true;
        this.genders.push({ gender_desc: "", gender_id: 0, });
      }
      this.selectedGender = 0;
    },

    ChangeVisibility(element, input) {
      if (element.className.includes("fa-eye-slash")) {
        element.className = "icon-eye cursor-pointer fas fa-eye";
      } else {
        element.className = "icon-eye cursor-pointer fas fa-eye-slash";
      }

      const password = document.getElementById(input);
      const type =
        password.getAttribute("type") === "password" ? "text" : "password";
      password.setAttribute("type", type);
    },
  },
  created() {
    this.admin = KPI.isAdmin()

    if (history.state.id)
      this.user_id = history.state.id
    
    this.GetUser()
    this.GetJobs()
    this.GetCategories()
    this.GetGenders()
  },
  updated() {
    if(!this.can_activate){
      const activeCheck = document.getElementById('active-check')
      if(activeCheck) {
        activeCheck.disabled = true
        Gems.Tooltip(activeCheck.parentElement, 'Limite de utilizadores ativos atingido.\nAdquira mais licenças', 'tooltip')
      }
    }
  }
};
</script>

<style scoped>

.pass-rule-container {
  margin: 0;
}

.user-container {
  width: 500px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.user-container div {
  margin: 10px;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.photo img {
  align-self: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
}

.photo {
  align-self: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 5px solid white;
  box-shadow: 0 0 15px 5px rgba(30, 40, 50, .2);
  margin-bottom: 40px !important;
}

.icon {
  font-size: 7em;
  margin: 20% auto auto auto;
}

.user-container .button {
  margin-bottom: 20px;
  width: 500px;
}

.user-container div label {
  margin: 0px 0px 5px 0px;
  font-size: 12px;
}

.user-container div input {
  width: 490px;
  height: 30px;
}

select,
.user-container div input  {
  border-color: var(--default-gray-color);
}

select {
  width: 502px;
  height: 40px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  border-radius: 50%;
}

h1 {
  margin: 20px;
  font-weight: 300;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  opacity: 0.6;
}

.cover {
  object-fit: cover;
  width: 150px;
  height: 100px;
}

.div-check-container {
  display: flex;
  flex-direction: row !important;
  justify-content: space-evenly;
  margin-top: 20px;
  width: 100%;
}

#teams-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row !important;
  margin: 0;
}

.div-card {
  background-color: white;
  border: solid 1px var(--default-gray-color);
  border-radius: 3px;
  padding: 10px;
  margin: 5px 0 !important;
}

.div-card:nth-child(n + 1) {
  margin-right: 5px !important;
}

.div-teams {
  align-self: flex-start;
  margin-left: 0px !important;
  margin-bottom: 5px !important;
}

#eye-password {
  position: relative;
  width: 20px;
  top: -36px;
  right: -464px;
  margin-bottom: -22px;
}

.icon-eye {
  font-size: 1em;
}

.icon-eye:hover {
  color: var(--default-app-color);
}

.teams-label {
  margin-bottom: 0px !important;
}

.div-admin-check,
.div-active-check {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 100%;
}

.admin-check,
.active-check {
  transform: scale(1.65);
  cursor: pointer;
  width: initial !important;
}

.disable-elements {
  pointer-events: none;
}

.div-splitted-inputs {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  width: 100%;
}

.div-splitted-inputs input, .div-splitted-inputs select {
  width: -webkit-fill-available !important;
}

.div-splitted-inputs label, .div-splitted-inputs, .div-input-left, .div-input-right {
  margin: 0 !important;
}

.div-input-left {
  width: 25%;
}

.div-input-right {
  width: 73%;
}
</style>
