import axios from "axios";
import { Gems } from "./gems";
import router from "./router";

class KPI {
  static ERROR_MESSAGE_GENERIC = "Ocorreu um erro!";

  static ABOVE100 = {
    NONE: 0,
    FA: 1,
    FO: 2,
  }

  static CheckCredentials() {
    document.addEventListener(
      "visibilitychange",
      async function () {
        if (!document.hidden && router.currentRoute.value.meta.requiresAuth == true) {
          await KPI.CallBackEnd("get", "check-credentials", { params: { cycle_id: localStorage.getItem("cycle") }, });
        }
      },
    );
  }

  static FillDDL(arr, ddlID, upperCase) {
    var ddl = document.getElementById(ddlID);
    for (let i = 0; i < arr.length; i++) {
      if (upperCase) {
        ddl.options.add(new Option(arr[i][1].toUpperCase(), arr[i][0]));
      } else {
        ddl.options.add(new Option(arr[i][1], arr[i][0]));
      }
    }
  }

  static ErrorOutline(elemID) {
    var elem = document.getElementById(elemID);
    elem.style.outlineStyle = "solid";
    elem.style.outlineWidth = "2px";
    elem.style.outlineColor = "orangered";
  }

  static RequiredFieldsValid() {
    var requiredFields = KPI.RequiredFields()
    if (requiredFields > 0) {
      if (requiredFields == 1) {
        Gems.Telegram("campo obrigatório", "tele-not-allowed")
        return false
      }

      Gems.Telegram(requiredFields + " campos obrigatórios", "tele-not-allowed")
      return false
    }
    return true
  }

  static RequiredFields() {
    var arrElems = [];
    var elems = document.getElementsByTagName("*");

    for (var i = 0; i <= elems.length; i++) {
      if (elems[i] != null) {
        if (
          elems[i].tagName == "INPUT" ||
          elems[i].tagName == "TEXTAREA" ||
          elems[i].tagName == "SELECT"
        ) {
          elems[i].style.outlineColor = "";
          elems[i].style.outlineStyle = "";

          if (elems[i].tagName == "INPUT" || elems[i].tagName == "TEXTAREA") {
            if (
              elems[i].hasAttribute("required") &&
              elems[i].value.trim().length == 0
            ) {
              KPI.ErrorOutline(elems[i].id);
              arrElems.push(elems[i].id);
            }
          } else {
            if (
              elems[i].hasAttribute("required") &&
              elems[i].selectedIndex == 0
            ) {
              KPI.ErrorOutline(elems[i].id);
              arrElems.push(elems[i].id);
            }
          }
        }
      }
    }

    return arrElems.length;
  }

  static async FormatWeight(weight) {
    let dot_index = weight.indexOf(".");
    if (dot_index != -1) {
      if (weight.slice(dot_index + 1).length > 2) {
        Gems.Telegram(
          "Não é permitido mais do que duas casas decimais!",
          "tele-not-allowed"
        );
        weight = weight.slice(
          0,
          dot_index + 3
        );
      }
    } else {
      if (weight > 100) {
        Gems.Telegram(
          "Não é permitido pesos maiores do que 100!",
          "tele-not-allowed"
        );
        weight = 100;
      }
    }

    return weight;
  }

  static async CallBackEnd(method, action, params) {
    const loggedUserId = sessionStorage.getItem('logged_user_id')

    let src = `../php/transaction.php?action=${action}&cycle_id=${localStorage.getItem('cycle')}`

    if (loggedUserId && !Number.isNaN(Number(loggedUserId)))
      src += `&logged_user_id=${loggedUserId}`

    let response = await axios[method](src, params).then((res) => {
      if (res.data != null) {
        if (res?.data?.logged_user_id)
          sessionStorage.setItem('logged_user_id', res.data.logged_user_id)

        res.data = res.data.response
        KPI.SessionHandler(res.data)
      }

      return res
    }).catch(() => {
      Gems.Telegram("Ocorreu um erro", "tele-not-allowed")
    })
    return response;
  }

  static SessionHandler(response) {
    let url = ''

    switch (response?.status) {
      case 0:
        if (window.parent.location.pathname != "/") {
          window.location.href = `${Gems.HubUrl()}/logout?message=Sessão expirada`
        }
        break;

      case 2:
      case 3:
        url = `${window.parent.location.origin}/cycles`

        window.parent.history.pushState({ change: true, message: `Nova sessão detetada` }, '', url);
        window.parent.history.go();
        break;

      case 4:
        window.location.href = `${Gems.HubUrl()}/logout?message=Utilizador inativo`
        break;
    }
    return;
  }

  static RoundValue(value, decimals) {
    return value == null ? "0.00" : parseFloat(value).toFixed(decimals);
  }

  static isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  static RealizationPercentage(objIndicator) {
    const I = objIndicator;
    let goal = parseFloat(I.ind_goal);
    let input = parseFloat(I.ind_vol);
    let mark = parseFloat(I.ind_mark);
    let res;
    let startPoint = mark + Math.abs(Math.abs(goal) - Math.abs(mark))

    if (I.ind_met_id == "5" || I.ind_met_id == "6") {
      res = input == 1 ? 100 : 0;
    } else {
      if (goal >= 0) {
        if (mark >= 0) {
          res =
            goal > mark
              ? (input * 100) / goal
              : ((startPoint - input) * 100) / (startPoint - goal);
        } else {
          res =
            (Math.abs(startPoint - input) * 100) / Math.abs(startPoint - goal);
        }
      }

      if (goal < 0) {
        if (mark >= 0) {
          res = ((startPoint - input) * 100) / (startPoint - goal);
        } else {
          res =
            goal < mark
              ? (startPoint - input) * 100 / startPoint - goal
              : (input * 100) / goal;
        }
      }
    }

    return res;

    // if(goal > -1 && mark > -1) {
    //     if(goal > mark) {
    //         res[0] = input * 100 / goal
    //     } else {
    //         res[0] = (startPoint - input) * 100 / (startPoint - goal)
    //     }
    // }

    // if(goal < 0 && mark > -1) {
    //     res[0] = (startPoint - input) * 100 / (startPoint - goal)
    // }

    // if(goal > -1 && mark < 0) {
    //     res[0] = Math.abs((startPoint - input)) * 100 / Math.abs((startPoint - goal))
    // }

    // if(goal < 0 && mark < 0) {
    //     if(goal > mark) {
    //         res[0] = Math.abs((startPoint - input)) * 100 / Math.abs((startPoint - goal))
    //     } else {
    //         res[0] = input * 100 / goal
    //     }
    // }
  }

  static EvaluationPercentage(objIndicator) {
    const I = objIndicator;
    let goal = parseFloat(I.ind_goal);
    let input = parseFloat(I.ind_vol);
    let mark = parseFloat(I.ind_mark);
    let above100 = I.above100;
    let startPoint = mark + Math.abs(Math.abs(goal) - Math.abs(mark))
    let res;

    I.ind_met_id_aux = "";
    if (input > goal && (I.ind_met_id == "1" || I.ind_met_id == "2")) {
      if (above100 == this.ABOVE100.FA) {
        I.ind_met_id_aux = "1";
      } else if (above100 == this.ABOVE100.FO) {
        I.ind_met_id_aux = "2";
      } else {
        I.ind_met_id_aux = `${I.ind_met_id}`;
      }
    } else if (input < goal && (I.ind_met_id == "2" || I.ind_met_id == "3")) {
      if (above100 == this.ABOVE100.FA) {
        I.ind_met_id_aux = "3";
      } else if (above100 == this.ABOVE100.FO) {
        I.ind_met_id_aux = "4";
      } else {
        I.ind_met_id_aux = `${I.ind_met_id}`;
      }
    } else {
      I.ind_met_id_aux = `${I.ind_met_id}`;
    }

    switch (I.ind_met_id_aux) {
      case "1":
        if (input === mark && mark === goal) {
          res = 100;
        } else {
          res = input >= mark ? (input * 100) / goal : 0;
        }
        break;
      case "2":
        if (input === mark && mark === goal) {
          res = 100;
        } else {
          res = input >= mark ? ((input - mark) * 100) / (goal - mark) : 0;
        }
        break;
      case "3":
        if (input === mark && mark === goal) {
          res = 100;
        } else if (goal <= mark) {
          res =
            input <= mark
              ? ((input - startPoint) * 100) / (goal - startPoint)
              : 0;
        } else {
          res =
            input >= mark
              ? ((input - startPoint) * 100) /
              (goal - startPoint)
              : 0;
        }
        break;
      case "4":
        if (input === mark && mark === goal) {
          res = 100;
        } else if (goal <= mark) {
          res = input <= mark ? ((input - mark) * 100) / (goal - mark) : 0;
        } else {
          res = input >= mark ? ((input - mark) * 100) / (goal - mark) : 0;
        }
        break;
      case "5":
      case "6":
        res = input == 1 ? 100 : 0;
        break;
      case "7":
        if (parseInt(I.direct_matching) == 0) {
          for (let metric of I.METRICS) {
            if (
              input >= parseInt(metric.option_value, 10) &&
              input < parseInt(metric.interval_max, 10)
            ) {
              metric.input = input;
              res = metric;
            }
          }
        } else {
          res = I.METRICS;
        }
        break;
    }

    if (above100 == this.ABOVE100.NONE && res > 100)
      res = 100;

    if (above100 != this.ABOVE100.NONE && res > 120)
      res = 120;

    return res;
  }

  static Cyrb53 = function (str, seed) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 =
      Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
      Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 =
      Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
      Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  static GetMark(objIndicator) {
    let goal = parseFloat(objIndicator.ind_goal);
    let mark = parseFloat(objIndicator.ind_mark);
    let startPoint = mark * 2 - goal;

    //(mark * 100) / goal - assure that the percentage equivalent value of the mark to do the gauge color scheme
    if (goal >= 0) {
      if (mark >= 0) {
        mark = goal > mark
          ? (mark * 100) / goal
          : ((startPoint - mark) * 100) / goal;
        // : mark; 
      } else {
        mark =
          (Math.abs(startPoint - mark) * 100) / Math.abs(startPoint - goal);
      }
    } else {
      if (mark >= 0) {
        mark = ((startPoint - mark) * 100) / (startPoint - goal);
      } else {
        mark =
          goal > mark
            ? (Math.abs(startPoint - mark) * 100) / Math.abs(startPoint - goal)
            : (mark * 100) / goal;
        // : mark;
      }
    }

    return mark;
  }

  static CheckForRefsTooltips(arrTooltips) {
    for (let tooltip in arrTooltips)
      if (arrTooltips[tooltip]["tooltip"] && arrTooltips[tooltip]["text"])
        Gems.Tooltip(arrTooltips[tooltip]["tooltip"], arrTooltips[tooltip]["text"], "tooltip")
  }

  static GetMatrixArrTooltips(refs, objIndicator) {
    let arrTooltips = [];

    arrTooltips.push({
      tooltip: refs.eval,
      text: objIndicator.ind_evaluation || objIndicator.ind_vol && objIndicator.ind_evaluation != '' ? objIndicator.ind_evaluation + "%" : '',
    });

    arrTooltips.push({
      tooltip: refs.reali,
      text: objIndicator.ind_realization || objIndicator.ind_vol && objIndicator.ind_realization != '' ? objIndicator.ind_realization + "%" : '',
    });

    arrTooltips.push({
      tooltip: refs.gaugeIcon,
      text: "Manómetros",
    });

    arrTooltips.push({
      tooltip: refs.commentIcon,
      text: "Comentários",
    });

    arrTooltips.push({
      tooltip: refs.btnSend,
      text: "Enviar comentário",
    });

    arrTooltips.push({
      tooltip: refs.disabled,
      text: "Não existem dados no indicador",
    });

    arrTooltips.push({
      tooltip: refs.designation,
      text: objIndicator.ind_desc,
    });

    arrTooltips.push({
      tooltip: refs.metric_description,
      text: objIndicator.metric_description,
    });

    arrTooltips.push({
      tooltip: refs.metric,
      text: objIndicator.ind_met,
    });

    arrTooltips.push({
      tooltip: refs['txtVolMetric0'],
      text: objIndicator.tooltip,
    });

    return arrTooltips;
  }

  static CovertNumToBool(arr, columnName) {
    for (let elem in arr) {
      if (arr[elem][columnName] == 0) {
        arr[elem][columnName] = false;
      } else {
        arr[elem][columnName] = true;
      }
    }
    return arr;
  }

  static isAdmin() {
    return JSON.parse(localStorage.getItem("admin")).value == 1
  }
}
export { KPI };
