<template>
  <tr v-show="gauge === true">
    <td colspan="13">
      <div class="gauge-div-text">
        <p>REALIZAÇÃO</p>
        <p>AVALIAÇÃO</p>
      </div>
    </td>
  </tr>
  <tr v-show="gauge === true">
    <td colspan="13">
      <div class="gauge-div">
        <div class="gauge-container">
          <div ref="gauge"></div>
        </div>
        <div class="gauge-container">
          <div ref="gauge2"></div>
        </div>
      </div>
    </td>
  </tr>
  <tr v-show="chat === true">
    <td colspan="13" class="td-chat">
      <div class="chat scrollbar">
        <div v-for="(message, index) in objIndicator.messages" :key="index">
          <div class="container-chat" :class="message.principal == true ? 'darker' : 'lighter'">
            <div class="left-container">
              <div class="photo-user" :class="message.principal == true ? 'right' : ''">
                <img class="cover" :src="message.sender_photo" v-if="message.src !== false && message.error == false" @error="message.error = true" />
                <i class="fas fa-user icon-user fa-3x" aria-hidden="true" v-else ></i>
              </div>
              <div :ref="'userName' + message.id">
                <p class="name">
                  {{ message.sender_professional_name }}
                </p>
              </div>
              <span :class="message.principal == true ? 'time-left' : 'time-right'">{{ message.datetime }}</span>
            </div>
            <div class="right-container">
              <div class="message-replied" v-if="message.reply != null">
                <p>
                  <b>{{ message.replied.sender_professional_name }}</b>
                  | {{ message.replied.datetime }}
                </p>
                <p>
                  {{ message.replied.message.substring(0, 85) + "..." }}
                </p>
              </div>
              <p>
                <i
                  class="fas fa-level-up-alt fa-rotate-90 replied"
                  aria-hidden="true"
                  v-if="message.reply != null"
                ></i>
                {{ message.message }}
              </p>
            </div>
            <div
              class="delete"
              v-if="objIndicator.ind_scope != 1 || cycleDetail == true"
            >
              <i
                class="fas fa-reply cursor-pointer"
                aria-hidden="true"
                @click="reply = message"
              ></i>
              <i
                class="fas fa-trash-alt cursor-pointer"
                aria-hidden="true"
                @click="ConfirmDeleteComment(message.id)"
              ></i>
            </div>
          </div>
        </div>
        <div class="margin-bottom"></div>
      </div>
      <div
        class="input-container"
        v-if="objIndicator.ind_scope != 1 || cycleDetail == true"
      >
        <div :class="typeof reply != 'function' ? 'reply' : 'hidden'">
          <div>
            <p>
              <b>{{ reply.sender_professional_name }}</b>
              | {{ reply.datetime }}
            </p>
            <p>
              {{ reply.message }}
            </p>
          </div>
          <i
            class="fas fa-times cancel cursor-pointer"
            aria-hidden="true"
            @click="reply = Array"
          ></i>
        </div>
        <div>
          <input
            type="text"
            ref="txtMessage"
            :v-model="txtMessage"
            @keyup.enter="SendComment(objIndicator)"
          />
          <button
            ref="btnSend"
            class="btn-icon"
            @click="SendComment(objIndicator)"
          >
            <i class="fas fa-paper-plane" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "MatrixTrRow",
  props: {
    gauge: Boolean,
    refresh: Boolean,
    chat: Boolean,
    objIndicator: Object,
    teamId: [String, Number],
    cycleDetail: Boolean,
  },
  data() {
    return {
      reply: Array,
      txtMessage: String,
    };
  },
  computed: {
    ...mapGetters(["UserIdState"]),
  },
  methods: {
    ...mapActions(['UpdateGauge']),

    ConfirmDeleteComment(id) {
      Gems.Telegram("Deseja apagar a mensagem ", "tele-info", "", "").then(
        () => {
          KPI.CallBackEnd("post", "delete-comment", { id })
            .then(() => {
              Gems.Telegram("Apagado com sucesso", "tele-success");
              this.GetComments();
            })
        },
        () => {}
      );
    },

    async SendComment(indicator) {
      if (this.$refs.txtMessage.value != "") {
        let receiverId;

        if (this.objIndicator.ind_scope == 1) {
          receiverId = localStorage.getItem("cycle");
        } else if (this.objIndicator.ind_scope == 2) {
          receiverId = this.teamId;
        } else {
          receiverId = this.UserIdState
        }

        await KPI.CallBackEnd("post", "send-comments", {
          receiverId: receiverId,
          indicator_id: indicator.id,
          message: this.$refs.txtMessage.value,
          cycle: localStorage.getItem("cycle"),
          scope_id: this.objIndicator.ind_scope,
          reply: typeof this.reply != "function" ? this.reply.id : null,
        }).then((response) => {
            this.$refs.txtMessage.value = null;
            this.GetComments();
            if (response.data.error) {
              Gems.Telegram(response.data.message, "tele-not-allowed");
            } else {
              this.reply = Array;
              Gems.Telegram(response.data.message, "tele-success");
              if (this.objIndicator.ind_scope == 3) {
                // this.SendNotification(receiverId);
              }
            }
        });
      } else {
        Gems.Telegram("Não há comentário a ser enviado", "tele-not-allowed");
      }
    },

    async SendNotification(receiverId) {
      await KPI.CallBackEnd("post", "send-notification", {
        receiverId,
      });
    },

    async GetComments() {
      let receiverId;

      if (this.objIndicator.ind_scope == 1) {
        receiverId = localStorage.getItem("cycle");
      } else if (this.objIndicator.ind_scope == 2) {
        receiverId = this.teamId;
      } else {
        receiverId = this.UserIdState;
      }

      // Object.assign(this.objIndicator, { messages: Array });
      await KPI.CallBackEnd("get", "get-comments", {
        params: {
          receiverId: receiverId,
          indicator_id: this.objIndicator.id,
          cycle: localStorage.getItem("cycle"),
          scope_id: this.objIndicator.ind_scope,
        },
      }).then((response) => {
        if (response.status == 200) {
          // this.objIndicator.message = response.data;
          Object.assign(this.objIndicator, { messages: response.data });
        }
      });
    },

    GaugeController(arrGauges, objIndicator) {
      const realization = parseFloat(objIndicator.ind_realization)
      const evaluation = parseFloat(objIndicator.ind_evaluation)
      const quali = objIndicator.quali
      const quali_color = objIndicator.quali_color
      const mark = KPI.GetMark(objIndicator)

      this.SetGaugeValue(arrGauges[0], 0, 0, realization, mark, false)
      this.SetGaugeValue(arrGauges[1], 0, 0, evaluation, mark, true, quali, quali_color)
    },

    CreateGauge(objIndicator, el, evaluation) {
      const mark = KPI.GetMark(objIndicator)
      const gauge = document.createElement("div")
      const gauge_body = document.createElement("div")
      const gauge_fill = document.createElement("div")
      const gauge_mark = document.createElement("div")
      const gauge_cover = document.createElement("div")
      const container_percent = document.createElement("div")
      const cover_percent = document.createElement("p")
      const percent = document.createElement("p")
      const cover_quali = document.createElement("p")

      percent.textContent = "%" 

      container_percent.appendChild(cover_percent)
      container_percent.appendChild(percent)
      gauge_cover.appendChild(container_percent)
      gauge_cover.appendChild(cover_quali)

      gauge_body.appendChild(gauge_fill)
      gauge_body.appendChild(gauge_mark)
      gauge_body.appendChild(gauge_cover)
      gauge.appendChild(gauge_body)

      cover_percent.className = "cover-percent"
      cover_quali.className = "cover-quali"
      gauge_body.className = "gauge-body"
      gauge_fill.className = "gauge-fill"
      gauge_cover.className = "gauge-cover"
      gauge.className = "gauge"
      percent.className = "percent"
      container_percent.className = "container-percent"

      if (!evaluation) {
        gauge_mark.className = "gauge-mark"
        gauge_mark.style.transform = `rotate(${mark / 100 / 2}turn)`
      }

      el.appendChild(gauge)
    },

    SetGaugeValue(gauge, value, speed, limit, mark, evaluation, quali = "", quali_color = "") {
      let rotateValue = 0
      let bgColor = ''
      let precision = 3

      if (value < 100) {
        rotateValue = value / 100

        bgColor = "#026ead"

        if (!evaluation && value <= mark)
          bgColor = "#DB3434"
      }

      if (value >= 100) {
        bgColor = "#009578"
        rotateValue = 1
      }

      gauge.querySelector(".gauge-fill").style.transform = `rotate(${rotateValue / 2}turn)`
      gauge.querySelector(".gauge-fill").style.background = bgColor

      value += 0.5
      if (value <= limit) {
        setTimeout(() => {
          if (limit - value < 20) {
            speed += 1
          } else if (value > 100) {
            speed -= 2
          }
          this.SetGaugeValue(gauge, value, speed, limit, mark, evaluation, quali, quali_color)
        }, speed)
      } else {
        if (limit.toString().length > 3)
          precision = 4

        value -= 0.5

        if (!evaluation)
          this.UpdateGauge(false)
      }

      // if (limit) {
        gauge.querySelector(".cover-percent").textContent = value.toPrecision(precision) // `${limit}`
        gauge.querySelector(".cover-quali").textContent = quali
        gauge.querySelector(".cover-quali").classList.add(`${quali_color}-quali-color`)
      // }
    },
  },
  watch: {
    gauge: function () {
      if (this.gauge) {
        this.GaugeController(
          [this.$refs.gauge, this.$refs.gauge2],
          this.objIndicator
        );
      }
    },

    refresh: function () {
      if (this.gauge) {
        this.GaugeController(
          [this.$refs.gauge, this.$refs.gauge2],
          this.objIndicator
        );
      }
    },

    chat: function () {
      if (this.chat) {
        this.GetComments();
      }
    },
  },
  mounted() {
    this.CreateGauge(this.objIndicator, this.$refs.gauge, false)
    this.CreateGauge(this.objIndicator, this.$refs.gauge2, true)
  },
};
</script>

<style scoped>

@media only screen and (max-width: 1400px) {

  .container-chat {
    width: 80%;
  }

  .left-container {
    width: 115px;
  }

  .message-replied {
    height: 22px;
  }
}

.gauge-container {
  width: 200px;
}

.gauge-div,
.gauge-div-text {
  display: flex;
  justify-content: space-evenly;
}

.gauge-div-text {
  margin: 10px 0px;
}

.gauge-div-text p {
  width: 200px;
  text-align: center;
  font-weight: bold;
  font-weight: 1.125em;
}

/* Chat containers */

.chat {
  height: 320px;
  overflow-y: auto;
  background-color: #ffffff;
}

.container-chat {
  border-radius: 5px;
  margin: 10px 10px 0 10px;
  display: flex;
}

.margin-bottom {
  width: 50%;
  display: flex;
  padding: 5px;
}

.left-container {
  text-align: center;
  margin: auto;
  padding: 10px;
}

.right-container {
  margin: 10px 10px 10px 20px;
  width: 485px;
}

.container-chat div {
  display: flex;
  flex-direction: column;
  line-break: anywhere;
}

.container-chat div p {
  margin: 0px;
}

/* Darker chat container */
.darker {
  border: 2px solid #9f9bb1;
  /* background-color: #ddd; */
  background-color: #eaeaea;
  float: right;
}

.lighter {
  border: 2px solid #a29eb4;
  /* background-color: #f1f1f1; */
  background-color: #ffffff;
  float: left;
}

/* Style images */
.container-chat img {
  float: left;
  max-width: 60px;
  width: 100%;
  border-radius: 50%;
}

/* Style the right image */
.container-chat img.right {
  float: right;
  /* margin-left: 20px; */
  margin-right: 0;
}

/* Style time text */
.time-right {
  float: right;
  color: #aaa;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}

.name {
  font-weight: 700;
}

.left {
  float: left;
}

.right {
  float: right;
}

.input-message {
  text-align: left;
  height: 30px;
  width: 800px !important;
}

.input-container > div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.input-container div input {
  flex: 1;
  height: 25px;
  text-align: left;
  margin: 10px;
  border: solid 1px #918aa7;
}

.btn-icon {
  height: 37px;
  width: 100px;
  padding-left: 4px;
  margin-right: 10px;
}

.photo-user {
  float: left;
  border-radius: 50%;
  /* height: 60px; */
  margin: auto auto 6px auto;
}

.photo-user img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.icon-user {
  width: 70%;
  height: 70%;
}

.cover {
  object-fit: cover;
  width: 150px;
  height: 100px;
}

.delete {
  margin-top: 10px;
  margin-right: 5px;
  flex-direction: row !important;
}

.delete i {
  margin-left: 5px;
  margin-right: 5px;
  height: 13px;
}

.delete i:hover {
  color: var(--default-app-color);
}

.reply {
  line-break: anywhere;
  padding: 12px 12px 0px 12px;
  display: flex !important;
}

.reply > div {
  flex: 1;
}

.reply > div p {
  width: 100%;
  text-align: start;
  margin: 0px 0px 5px 0px;
}

.cancel {
  font-size: 1.3em;
  color: black !important;
  align-self: flex-start;
}

.cancel:hover {
  transform: scale(1.03);
}

.message-replied {
  color: rgb(88, 86, 86) !important;
  font-size: 0.85em;
  border-bottom: 1px solid #c7c7c7;
  margin-bottom: 2px;
}

.message-replied p:last-child {
  margin-bottom: 5px;
}

.replied {
  color: rgb(88, 86, 86) !important;
  margin-right: 5px;
}
</style>
