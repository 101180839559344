<template>
  <div class="modal" :class="{ ativo: modalAtivo }" v-if="releases">
    <div class="realeases">
      <h2 class="modal-title">Atualizações mais recentes</h2>
      <div class="releases-list">
        <div class="release-item" v-for="(release, i) in releasesArr" :key="i">
          <div class="release-date">
            <span class="release-item-date">{{ release.release_date }}</span>
            <ul class="ul-item">
              <li class="li-item"></li>
            </ul>
          </div>
          <div class="release-item-text">
            <span class="release-item-title">{{ release.title }}</span>
            <span class="release-item-description">{{
              release.description
            }}</span>
          </div>
        </div>
      </div>

      <div class="release-btns">
        <button class="cancel" @click="modalAtivo = false">
          VER MAIS TARDE
        </button>
        <button
          class="btn-action"
          @click="ConfirmReleases(releasesArr[0]['max_release'])"
        >
          TOMEI CONHECIMENTO
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  emits: ["update-releases"],
  props: {
    activate: Boolean,
    releases: Boolean,
    releasesArr: [Array, Object],
  },
  data() {
    return {
      modalAtivo: false,
      direct: false,
    };
  },
  methods: {
    ConfirmReleases(max_release) {
      this.$emit("update-releases", max_release);
      this.modalAtivo = false;
    },
  },
  watch: {
    activate() {
      this.modalAtivo = this.activate;
    },
  },
  mounted() {
    this.modalAtivo = this.activate;
  },
};
</script>

<style scoped>
.modal::before {
  content: "";
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  display: none;
  background: #000000b0;
  color: #5c5c5c;
}

.modal.ativo {
  display: block;
  z-index: 50;
}

.realeases {
  width: 50%;
  margin: 4% auto;
  background: #f8f8f8;
  padding: 16px;
  color: #4d4d4d;
  font-size: 0.9vw;
}

.release-item-title,
.release-item-date {
  display: block;
  font-weight: bold;
}

.release-item-title {
  margin-bottom: 9px;
}

.releases-list {
  background: #fff;
  padding: 20px;
}

.modal-title {
  margin: 0 0 10px 0;
}

.release-btns {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.release-btns button {
  width: 25%;
  font-size: 12px !important;
}

.cancel {
  margin-right: 5px;
}

.releases-list > :not(:first-child) {
  margin-top: 5%;
}

.release-item {
  display: inline-grid;
  grid-template-columns: fit-content(100%) fit-content(100%);
  grid-gap: 15px;
}

.release-item-description {
  word-break: break-word;
  margin-top: 5px;
}

.ul-item {
  float: right;
  margin: 2px -12px 0px 0px;
}

.li-item {
  font-size: 1.5em;
}
</style>
