<template>
  <div class="frame">
    <div class="header" v-if="typeof user_id != 'function'">
      <UserHeader :userId="user_id" id="myHeader" />
    </div>

    <div class="cards-container" v-show="permission != null">
      <div class="left">
        <div :key="team.team_id" v-for="(team, _, i) in arrTeams">
          <div class="divTeam" v-if="i % 2 == 0" :class="StackControllerLeft()">
            <header class="item-header">
              <span class="team-title" :class="team[0].team_deleted == 1 ? 'user-removed' : ''" 
                :ref="'teamTitle' + team[0].team_id"
                @mouseenter="TooltipIfNeeded($refs['teamTitle' + team[0].team_id][0])"
              >{{ team[0].team_name }}</span>
            </header>
            <div class="item-container" :class="team[0].team_deleted == 1 ? 'user-removed' : ''">
              <section
              class="user-row"
                :class="IsClickable(user)"
                :key="user.user_id"
                v-for="user in team"
                @click="GoToMatrix(user)"
              >
                <UserBlock
                  :objTeamUser="user"
                  :teamUser="true"
                  :openTeam="true"
                  :loggedUserIsLeader="leaderInTeams.includes(user.team_id)"
                  :canOpen="SeeMatrix(user)"
                />
              </section>
            </div>
          </div>
        </div>
      </div>

      <div class="right">
        <div :key="team.team_id" v-for="(team, _, i) in arrTeams">
          <div class="divTeam" v-if="i % 2 != 0" :class="StackControllerRight()">
            <header class="item-header">
              <span class="team-title" :class="team[0].team_deleted == 1 ? 'user-removed' : ''"
                :ref="'teamTitle' + team[0].team_id"
                @mouseenter="TooltipIfNeeded($refs['teamTitle' + team[0].team_id][0])"
                >{{ team[0].team_name }}</span
              >
            </header>

            <div class="item-container" :class="team[0].team_deleted == 1 ? 'user-removed' : ''">
              <section class="user-row" :class="IsClickable(user)"
                :key="user.user_id" v-for="user in team" @click="GoToMatrix(user)">
                <UserBlock
                  :objTeamUser="user"
                  :teamUser="true"
                  :openTeam="true"
                  :loggedUserIsLeader="leaderInTeams.includes(user.team_id)"
                  :canOpen="SeeMatrix(user)"
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserHeader from "../components/UserHeader";
import UserBlock from "../components/UserBlock";
import { KPI } from "../kpi";
import { mapGetters, mapActions } from "vuex";
import { Gems } from "../gems";

export default {
  name: "user-teams",
  emits: ["force-reload"],
  components: {
    UserHeader,
    UserBlock,
  },
  data() {
    return {
      arrTeams: [],
      user_id: Number,
      admin: Number,
      stackLeft: ["purple", "second-purple"],
      stackRight: ["second-purple", "purple"],
      permission: null,
    };
  },
  methods: {
    SeeMatrix(user) {
      let canSee = user.has_matrix == 1 && (user.is_subordinate == 1 || this.user_id == user.user_id || this.admin)

      if (this.leaderInTeams.length > 0 && !canSee) {
        canSee = this.leaderInTeams.includes(user.team_id);
      }
      return canSee;
    },

    GoToMatrix(user) {
      if (this.SeeMatrix(user)) {
        this.$router.push({
          name: "matrix",
          state: { userId: user.user_id, teamId: user.team_id },
        });
      }
    },

    IsClickable(user) {
      let cssClass = "";
      let was_on_team = user.was_on_team == 1 ? true : false;

      if (was_on_team && user.team_deleted != 1) {
        cssClass += "was-on-team";
      }

      if (this.SeeMatrix(user)) {
        cssClass += " cursor-pointer";
      }

      return cssClass;
    },

    StackControllerLeft() {
      let res = this.stackLeft.shift();
      this.stackLeft.push(res);
      return res;
    },

    StackControllerRight() {
      let res = this.stackRight.shift();
      this.stackRight.push(res);
      return res;
    },

    ...mapActions(["StoreId", "FetchMatrix"]),

    async GetTeams(userId) {
      const response = await KPI.CallBackEnd("get", "get-teams-by-user", {
        params: {
          user: userId,
          cycle: localStorage.getItem("cycle"),
        },
      })

      if (response.status != 200) {
        Gems.Telegram(KPI.ERROR_MESSAGE_GENERIC, "tele-not-allowed")
        return
      }

      this.user_id = response.data.userId
      this.arrTeams = response.data.teams
      this.permission = response.data.permission
      this.leaderInTeams = response.data.leaderInTeams
    },

    OpenMatrix(objUser, team_id) {
      if (typeof objUser !== "object") {
        this.$router.push({
          name: "matrix",
          params: { userId: objUser, teamId: team_id },
        });
      } else {
        this.$router.push({
          name: "matrix",
          params: { userId: objUser.user_id, teamId: team_id },
        });
      }
    },

    TooltipIfNeeded(elm) {
      if (elm.classList.contains("user-removed")) {
        Gems.Tooltip(
          elm,
          "Equipa apagada mas os utilizadores possuem matriz",
          "tooltip"
        );
      }
    },
  },
  computed: {
    ...mapGetters(["idStore"]),
  },
  mounted() {
    if (history.state.userId) {
      this.StoreId(history.state.userId);
      this.GetTeams(history.state.userId);
    } else {
      this.admin = KPI.isAdmin()
      this.GetTeams();
    }
  },
};
</script>

<style scoped>
.frame {
  padding: 0px 15px 50px 15px;
}

.cards-container {
  /* columns: 2; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
}

.divTeam {
  display: inline-block;
  text-align: center;
  padding: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(30, 60, 90, 0.1);
  width: 100%;
  color: white;
}

.team-title {
  font-size: 1.525em;
  font-weight: 500;
  line-height: 1.6;
  margin: 0px;
}

.user-removed {
  opacity: 0.5;
  cursor: default;
}

.divTeam:nth-child(n + 1) {
  margin-top: 10px;
}

.purple {
  background-color: var(--default-app-color);
}

.second-purple {
  background-color: var(--second-app-color);
}

.purple .user-row:hover {
  background-color: #dfccfe30 !important;
}

.second-purple .user-row:hover {
  background-color: #dfccfe40 !important;
}

.header {
  width: 100%;
  height: 120px;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 1011px;
}

.was-on-team {
  opacity: 0.5;
}

@media only screen and (max-width: 1400px) {
  .header {
    width: 805px;
  }

  .sticky {
    width: 805px;
  }
}
</style>
