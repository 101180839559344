<template>
  <tr class="indicator-row" :class="{'without-result-row' : !KPI.isNumeric(objIndicator.ind_vol)}" ref="indicator">
    <td :class="{'without-result-line' : !KPI.isNumeric(objIndicator.ind_vol)}"></td>

    <td class="td-id">{{ objIndicator.ind_internal }}</td>

    <td class="td-desig" ref="designation">
      <span class="line-clamp"> {{ objIndicator.ind_desig }} </span>
    </td>
    
    <td class="td-weight">
      <input type="text" class="txt" ref="txtWeight" :value="Gems.FormatDecimals(objIndicator.ind_weight ?? 0, 2)" @change="Update('txtWeight')" :disabled="IsGaugeChanging" maxlength="4" />
    </td>
    
    <td class="td-metric" ref="metric_description"> {{ objIndicator.ind_met }} </td>
    
    <td class="td-unit">{{ objIndicator.ind_unit }}</td>

    <td class="td-goal" v-if="objIndicator.ind_met_id < 7">
      <input type="text" class="txt" ref="txtGoal" :value="objIndicator.ind_goal ? Gems.FormatDecimals(objIndicator.ind_goal, 2) : ''" @change="Update('txtGoal')" :disabled="IsGaugeChanging" />
    </td>
    <td class="td-goal" v-else></td>

    <td class="td-unit" v-if="objIndicator.ind_met_id < 5">
      <input type="text" class="txt" ref="txtMark" :value="objIndicator.ind_mark ? Gems.FormatDecimals(objIndicator.ind_mark, 2): ''" @change="Update('txtMark')" :disabled="IsGaugeChanging" />
    </td>
    <td class="td-unit" v-else></td>

    <td class="td-result">
      <div v-if="objIndicator.ind_met_id == 7">
        <div v-if="objIndicator?.METRICS?.length > 0">
          <input type="text" v-if="objIndicator.direct_matching == 0"  class="txt-result"
            :value="objIndicator.ind_vol" ref="txtVolMetric0"
            @keyup="$store.commit('calculateEvaluation', { indicator: objIndicator, volume: this.$refs.txtVolMetric0.value,})"
          />
          
          <Dropdown v-else :text="objIndicator.title ?? objIndicator.METRICS.length" :interval="true" :isOpen="openDDL" :tooltip="objIndicator.tooltip" @toggle="openDDL = !openDDL">
            <DropdownItem v-for="(row, index) in objIndicator.METRICS" :key="index" :interval="true" :tooltip="row.tooltip" @percentage="SetDirectMatchingEvaluation(objIndicator, row)">
              {{ row.option_value }}
            </DropdownItem>
          </Dropdown>
        </div>
        
        <div v-else ref="disabled">
          <input type="text" class="txt-result" disabled/>
        </div>
      </div>
      
      <div v-else-if="objIndicator.ind_met_id == 5 || objIndicator.ind_met_id == 6">
        <IndicatorBooleanVolume :objIndicator="objIndicator" :disabled="false" @ChangeRadio="ChangeRadio($event)" />
      </div>
      
      <input type="text" v-else class="txt-result" ref="txtVol" :value="KPI.isNumeric(objIndicator.ind_vol) ? objIndicator.ind_vol : '--'" @change="Percentage(objIndicator)" :disabled="IsGaugeChanging"/>
    </td>

    <td class="td-realization" ref="reali">{{ objIndicator.ind_realization || KPI.isNumeric(objIndicator.ind_vol) ? Gems.FormatDecimals(objIndicator.ind_realization, 2) + '%' : '--' }}</td>

    <td class="td-realization" ref="eval">{{ objIndicator.ind_evaluation || KPI.isNumeric(objIndicator.ind_vol) ? Gems.FormatDecimals(objIndicator.ind_evaluation, 2) + '%' : '--' }}</td>
    
    <td class="td-above100">
      <select class="ddl-above100" :class="{'cursor-pointer': objIndicator.ind_met_id != 5 && objIndicator.ind_met_id != 6}" ref="above100" @change="Update('above100')" v-if="objIndicator.ind_met_id != 5 && objIndicator.ind_met_id != 6">
        <option :selected="objIndicator['above100'] == KPI.ABOVE100.NONE" :value="KPI.ABOVE100.NONE">N/A</option>
        <option :selected="objIndicator['above100'] == KPI.ABOVE100.FA" :value="KPI.ABOVE100.FA">FA</option>
        <option :selected="objIndicator['above100'] == KPI.ABOVE100.FO" :value="KPI.ABOVE100.FO">FO</option>
      </select>
    </td>

    <td  v-if="scopeId == 3" class="td-gauge cursor-pointer" :class="`${objIndicator.status_messages}`" @click="Show('chat')" ref="commentIcon">
      <i class="fas fa-comments" aria-hidden="true"></i>
    </td>

    <td  v-if="scopeId == 3" class="td-gauge cursor-pointer" :class="`${objIndicator.quali_color}-quali-color`" @click="Show('gauge')" ref="gaugeIcon">
      <i class="fas fa-tachometer-alt" aria-hidden="true"></i>
    </td>

  </tr>
  <MatrixTrRow
    :objIndicator="objIndicator"
    :chat="chat"
    :gauge="gauge"
    :refresh="refresh"
  />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Dropdown, DropdownItem } from "./dropdown/";
import { KPI } from "../kpi";
import { Gems } from '../gems';
import MatrixTrRow from "./MatrixTrRow.vue";
import IndicatorBooleanVolume from "./IndicatorBooleanVolume.vue";
import { useTableMetric } from "../composables/useTableMetric"

export default {
  name: "MatrixIndicatorBlock",
  setup(){
    return { useTableMetric }
  },
  props: {
    objIndicator: Object,
    scopeId: [String, Number],
  },
  components: {
    Dropdown,
    DropdownItem,
    MatrixTrRow,
    IndicatorBooleanVolume,
  },
  data() {
    return {
      indicator: Object,
      openDDL: false,
      radio: Boolean,
      gauge: false,
      admin: Boolean,
      chat: false,
      txtMessage: "",
      above100: Boolean,
      reply: Array,
      refresh: false,
      Gems,
      KPI,
    };
  },
  computed: {
    ...mapGetters([
      "PercentageState",
      "IsGaugeChanging",
      "GetEvaluationSelected",
    ]),
  },
  methods: {
    ...mapActions(["UpdateGauge", "UserEvaluation", "ShowGauge", "ValidateIndicator"]),

    ...mapMutations(["calculateEvaluation", "calculateDirectMatchingEvaluation", 'updateMatrixIndicator']),

    CheckForGauge() {
      if (this.gauge === true) {
        this.UpdateGauge(true);
        this.refresh = !this.refresh;
      }
    },

    async Percentage(obj) {
      if(this.scopeId == 3) {
        Object.assign(obj, { ind_vol: this.$refs?.txtVol?.value });
        await this.UserEvaluation()
        this.CheckForGauge()
      } else {
        this.calculateEvaluation({ indicator: this.objIndicator, volume: this.$refs?.txtVol?.value, })
      }
    },

    async SetDirectMatchingEvaluation(indicator, optionSelected) {
      Object.assign(this.objIndicator, { tooltip: '' });
      await this.calculateDirectMatchingEvaluation({ indicator, optionSelected });
      this.openDDL = false;
      this.CheckForGauge();
      Object.assign(this.objIndicator, { tooltip: optionSelected.tooltip });
    },

    async ChangeRadio(volume) {
      Object.assign(this.objIndicator, {
        ind_vol: volume,
      })

      if(this.scopeId == 3) {
        await this.UserEvaluation()
        this.CheckForGauge()
      } else {
        this.calculateEvaluation({ indicator: this.objIndicator, volume: this.objIndicator.ind_vol })
      }
    },

    async Show(type) {
      const res = await this.ShowGauge(
        {
          gauge: this.gauge,
          chat: this.chat,
          commentIcon: this.$refs.commentIcon,
          gaugeIcon: this.$refs.gaugeIcon,
          indicator: this.$refs.indicator,
          type
        }
      )

      this.gauge = res[0]
      this.chat = res[1]
    },

    async Update(refName) {
      const auxIndicator = []
      
      Object.assign(auxIndicator, {
        ind_weight: this.$refs.txtWeight.value,
        ind_goal: auxIndicator.ind_met_id == 7 ? null : this.$refs?.txtGoal?.value,
        ind_mark: auxIndicator.ind_met_id >= 5 ? null : this.$refs?.txtMark?.value,
        ind_met_id: this.objIndicator.ind_met_id,
        above100: this.$refs.above100.value
      })

      const res = await this.ValidateIndicator(auxIndicator)
      if (!res) {
        this.$refs[refName].value = ""
        return
      }

      this.updateMatrixIndicator({indicator: this.objIndicator, new_indicator: auxIndicator})
      this.Percentage(this.objIndicator)
    },
  },
  mounted() {
    this.admin = KPI.isAdmin()

    this.useTableMetric(this.objIndicator)

    let arr = KPI.GetMatrixArrTooltips(this.$refs, this.objIndicator)
    KPI.CheckForRefsTooltips(arr)
  },
  updated() {
    let arr = KPI.GetMatrixArrTooltips(this.$refs, this.objIndicator)
    KPI.CheckForRefsTooltips(arr)

  },
};
</script>

<style scoped>

</style>
