<template>
  <div class="container-table">
    <div class="first-row">
      <div>
        <h3>Regras</h3>
        <ul>
          <li>Nome do ficheiro: [ID do indicador]_[data do resultado]. Ex.: 1005_2021-05-09
          </li>
          <li>O ficheiro Excel deverá conter uma linha de cabeçalho</li>
          <li>A ordem das colunas deverá respeitar a ordem do <b>"Exemplo"</b></li>
          <li>Métrica binária: S ou N</li>
          <li>Adicione apenas ficheiros ".csv" com codificação UTF-8
            <i class="fas fa-info-circle icon cursor-pointer" aria-hidden="true"
              @click="this.showImportModal = true"></i>
          </li>
        </ul>
      </div>

      <div>
        <h3>Exemplo</h3>
        <table class="tb-example">
          <thead>
            <tr>
              <th>1. N° interno do utilizador</th>
              <th>2. Resultado <i class="fas fa-info-circle icon cursor-pointer" aria-hidden="true" id="table-help"
                  @click="this.showModalTableMetrics = true"></i></th>
              <th>3. N° da equipa</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ex.: A001</td>
              <td>Ex.: A020</td>
              <td>Ex.: 35</td>
            </tr>
            <tr>
              <td>(campo alfa-numérico)</td>
              <td>(campo alfa-numérico)</td>
              <td>(campo numérico)</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="second-row">
      <div class="input-table">
        <div class="upload-file">
          <div>
            <input ref="labelInput" class="inputfile cursor-pointer" type="text"
              onclick="document.getElementById('input').click()" :placeholder="files === null
                ? 'Selecionar ficheiro(s) [ CSV UTF-8 (Comma delimited) (*.csv) ]'
                : files.length > 1
                  ? files.length + ' arquivos selecionados'
                  : files.length + ' arquivo selecionado'
                " readonly />
            <input class="hidden-input" id="input" type="file" ref="files" @change="SelectFile()" multiple />
          </div>
          <button @click="Import()">
            INCLUIR <i class="far fa-arrow-alt-circle-down arrow-icon"></i>
          </button>
        </div>

        <div>
          <div class="div-imported-files-container scrollbar scrollbar-y">
            <div v-for="(file, index) in fileList" :key="index" class="div-imported-files-row">
              <span>
                <i class="fas fa-file icon-file" aria-hidden="true"></i>
              </span>

              <span class="name cursor-pointer" @click="Download(file)" :ref="'file_' + file">
                {{ file }}
              </span>

              <span @click="ConfirmDeleteFile(file)">
                <i :ref="'trash_' + file" class="fas fa-trash red cursor-pointer" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="upload-results">
          <button class="btn-action" @click="ConfirmUploadResults()">INICIAR PROCESSO</button>
        </div>
      </div>

      <div class="div-history-container">
        <h3>Histórico</h3>
        <div class="imported scrollbar">
          <table class="fileTable">
            <thead>
              <tr>
                <th>Nome do ficheiro</th>
                <th>Data dos resultados</th>
                <th>Data de importação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(file, index) in importedFileList" :key="index">
                <td>{{ file.filename }}</td>
                <td>{{ file.file_date }}</td>
                <td>{{ file.import_date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <section id="modal" class="modal" :class="{ ativo: showImportModal }">
    <div class="div-import-help modal-container">
      <button class="fas fa-times close-import" @click="showImportModal = false"></button>
      <li>Grave o ficheiro com o seguinte formato:</li>
      <img style="margin-top: 5px" src="../images/excel.png" />
    </div>
  </section>

  <section id="modal" class="modal" :class="{ ativo: showModalTableMetrics }">
    <div class="div-import-help div-metric-help modal-container">
      <button class="fas fa-times close-import" @click="showModalTableMetrics = false"></button>
      <div>
        <div>No caso de métrica Tabela [Correspondência direta] usar o [Código do Resultado]</div>

        <div class="indicator-container header-container">
          <div class="header-row">
            <span class="span-left-ind">Código</span>
            <span class="span-right-ind span-header-right-ind">Indicador [Resultados]</span>
          </div>
        </div>

        <div v-for="indicator in arrTableMetricsIndicators" :key="indicator.id">
          <div class="indicator-container" v-if="indicator.direct_matching">
            <div class="metric-row ind-row">
              <span class="span-left-ind">{{ indicator.ind_internal }}</span>
              <span class="span-right-ind">{{ indicator.ind_desig }}</span>
            </div>

            <div v-for="metric in indicator['METRICS']" :key="metric.id">
              <div class="metric-row">
                <span class="span-left-ind">{{ metric.id }}</span>
                <span class="span-right-ind">{{ metric.option_value }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "Results",
  data() {
    return {
      cycleName: null,
      files: null,
      fileList: [],
      importedFileList: [],
      showImportModal: false,
      showModalTableMetrics: false,
      arrTableMetricsIndicators: [],
      Gems,
    };
  },
  methods: {
    async Download(name) {
      const response = await KPI.CallBackEnd("post", "download-result", {
        params: {
          path: name,
          cycle: localStorage.getItem("cycle"),
          cycle_name: this.cycleName,
        },
      })

      Gems.ExportExcel(response.data)
    },

    clickForaModal({ target, currentTarget }) {
      if (target === currentTarget) this.showImportModal = false;
    },

    ConfirmDeleteFile(filename) {
      Gems.Telegram(`Apagar ficheiro ${filename}?`, "tele-info", "", "")
        .then(
          () => { this.DeleteFile(filename) },
          () => { }
        )
    },

    async DeleteFile(filename) {
      const response = await KPI.CallBackEnd("post", "delete-file", {
        cycle: localStorage.getItem("cycle"),
        name: filename,
      })

      if (response.status == 200 && response.data.error == true)
        Gems.Telegram(response.data.message, "tele-not-allowed")

      this.GetFilesList()
    },

    ConfirmUploadResults() {
      Gems.Telegram("Deseja iniciar o processo de carregamento?", "tele-info", "", "")
        .then(
          () => { this.UploadResults() },
          () => { }
        );
    },

    async UploadResults() {
      const response = await KPI.CallBackEnd("post", "import-results", {
        cycle: localStorage.getItem("cycle"),
        cycle_name: this.cycleName,
      })

      if (response.error) {
        Gems.Telegram(response.data.message, "tele-not-allowed")
        return
      }

      Gems.Telegram("Processo concluído", "tele-success")
      this.GetFilesList()
    },

    SelectFile() {
      this.files = this.$refs.files.files;
    },

    async Import() {
      const formData = new FormData();

      for (let file in this.$refs.files.files) {
        if (typeof this.$refs.files.files[file] == "object") {
          formData.append(file, this.$refs.files.files[file]);
        }
      }
      formData.append("cycle_id", localStorage.getItem("cycle"));
      formData.append("cycle_name", this.cycleName);
      try {
        const response = await KPI.CallBackEnd("post", "import-results-excel", formData)
        if (response.status == 200) {
          if (response.data.error == true)
            Gems.Telegram(response.data.message, "tele-not-allowed")
          else
            Gems.Telegram("Ficheiro(s) adicionado(s)", "tele-success")
        }
        this.GetFilesList()
      } catch (err) {
        Gems.Telegram("Contacte o administrador", "tele-not-allowed")
      }
      this.$refs.files.value = null;
      this.files = null;
    },

    async GetFilesList() {
      await KPI.CallBackEnd("get", "get-files-to-import", {
        params: { id: localStorage.getItem("cycle") },
      }).then((res) => {
        this.cycleName = res.data[0];
        this.fileList = res.data[1];
        this.importedFileList = res.data[2];
      });
    },
  },
  async mounted() {
    this.GetFilesList()
    Gems.Tooltip(this.$refs.labelInput, "Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]", "tooltip")

    const response = await KPI.CallBackEnd("get", "get-intervals")

    this.arrTableMetricsIndicators = response.data
  },
  updated() {
    for (let file in this.fileList) {
      let name = "trash_" + this.fileList[file]
      let fileRef = "file_" + this.fileList[file]

      if (this.$refs[name])
        Gems.Tooltip(this.$refs[name][0], "Apagar Ficheiro", "tooltip")

      if (this.$refs[fileRef])
        Gems.Tooltip(this.$refs[fileRef][0], "Fazer download", "tooltip")
    }
  },
};
</script>

<style scoped>
.container-table {
  padding: 19px;
}

table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  word-wrap: break-word;
}

table td,
table th {
  border: 1px solid var(--medium-gray-color);
  padding: 3px 2px;
}

table th {
  background: var(--medium-gray-color);
  text-align: center;
  font-weight: normal !important;
}

.imported {
  height: 625px;
  overflow-y: auto;
  overflow-x: hidden;
}

.first-row,
.second-row {
  display: flex;
  justify-content: space-between;
}

.second-row {
  margin-top: 25px;
}

.tb-example {
  width: 500px;
  margin-top: 13px;
}

.second-row div h3 {
  margin-bottom: 13px;
}

h3 {
  margin: 0;
}

.input-table {
  width: 450px;
}

.div-imported-files-container {
  border-collapse: collapse;
  box-shadow: inset 0 0 5px 0 gray;
  border-radius: 4px;
  height: 200px;
  background-color: #f7f7f7;
}

.div-imported-files-row {
  display: flex;
  justify-content: space-between;
  padding: 3% 3% 0% 3%;
}

table tr {
  height: 30px;
}

.fileTable thead th:nth-child(1) {
  width: 45%;
}

.name {
  width: -webkit-fill-available;
  padding-left: 10px;
}

.icon-file {
  font-size: 1.2em;
}

.upload-file {
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}

.upload-file button {
  padding: 1px 0 0 0;
  height: 28px;
  font-size: 13px;
  margin-left: 5px;
}

.inputfile {
  width: 345px;
  text-align: center;
  color: var(--default-gray-color);
  background-color: white;
  border: 1px solid var(--medium-gray-color);
}

.hidden-input {
  display: none;
}

.modal {
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  cursor: default;
  width: 600px;
}

.modal.ativo {
  display: block;
  z-index: 1000;
}

.modal-container {
  top: -180px;
  font-size: var(--default-font-size);

}

.modal-container li {
  margin-left: 0;
}

.div-metric-help {
  width: 600px;
  padding: 2%;
}

.upload-results {
  margin-top: 5px;
  width: 100%;
}

.icon {
  font-size: 1.125em;
}

li {
  margin-left: -22px;
}

.fa-trash {
  font-size: 1.2em;
}

.fa-trash:hover {
  color: red;
}

.disabled {
  cursor: default;
  opacity: 0.8;
}

.disabled:hover {
  border: 1px solid var(--medium-gray-color);
  color: var(--font-color);
}

.arrow-icon {
  margin-left: 1px;
  font-size: 1.3em;
  vertical-align: middle;
}

.ind-row {
  background-color: var(--default-app-color);
  color: white;
}

.header-container {
  margin-top: 10px;
  border-bottom: 0px !important;
}

.header-row {
  display: flex;
  background-color: var(--medium-app-color);
  font-weight: 600;
  padding: 2px;
}

.span-left-ind {
  min-width: 15%;
  max-width: 15%;
  text-align: center;
  border-right: 1px solid var(--medium-app-color);
}

.span-right-ind,
.span-header-right-ind {
  width: 85%;
  text-align: left;
}

.span-right-ind {
  padding-left: 20px;
}

.span-header-right-ind {
  padding-left: 10px;
}

.metric-row {
  display: flex;
}

.indicator-container {
  border: 1px solid var(--medium-app-color);
}

.metric-header {
  font-weight: 600;
}

.div-history-container {
  width: 500px;
}

.bold {
  font-weight: 600;
}

@media only screen and (max-width: 1400px) {

  table,
  .div-history-container,
  .input-table,
  .div-imported-files-container {
    width: 390px !important;
  }

  .inputfile {
    width: 280px !important;
  }

  .modal {
    left: 58%;
  }
}
</style>
