<template>
  <div>
    <table class="tbl">
      <tr>
        <th>
          <i
            class="fas fa-arrow-circle-left icon fa-2x cursor-pointer"
            @click="$router.push({ name: 'templates' })"
            aria-hidden="true"
          ></i>
        </th>
        <th>
          <div class="divDesig" v-if="arrTemplate['TEMPLATE'] != undefined">
            <input
              type="text"
              v-model="arrTemplate['TEMPLATE'][0]['temp_desig']"
              class="template-title"
            />
          </div>
        </th>
        <th class="tdButtons">
          <button class="btn-action" @click="Save">GRAVAR</button><br />
          <button @click="CloneTemplate">CLONAR</button>
        </th>
      </tr>
    </table>
    <SearchIndicator
      :templateID="templateId"
      class="search"
      v-if="templateId !== ''"
    /><br />
    <TemplateScopes
      :arrScopes="arrTemplate['SCOPES']"
      :templateId="templateId"
    />
  </div>
</template>

<script>
import SearchIndicator from "../components/SearchIndicator";
import TemplateScopes from "../components/TemplateScopes";
import { mapGetters, mapActions, mapMutations } from "vuex";

import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "template",
  components: {
    SearchIndicator,
    TemplateScopes,
  },
  data() {
    return {
      templateName: "",
      show: false,
      templateId: "",
    };
  },
  methods: {
    ...mapActions(["FetchTemplate", "SaveTemplate"]),

    ...mapMutations(['resetTemplate']),

    async Save() {
      const response = await this.SaveTemplate()

      if (response) 
        this.$router.push({ name: "templates" })
        
    },

    CloneTemplate() {
      Gems.Telegram("Deseja clonar o modelo?", "tele-info", "", "").then(
        () => {
          let temp_desig =
            this.arrTemplate["TEMPLATE"][0].temp_desig + " (clonado)";

          this.DuplicateTemplate(this.templateId, temp_desig);
        },
        () => {}
      );
    },

    async DuplicateTemplate(templateId, tempDesig) {
      const response = await KPI.CallBackEnd("post", "clone-template", {
        temp_id: templateId,
        temp_desig: tempDesig,
      })

      if (response.status != 200) {
        Gems.Telegram(KPI.ERROR_MESSAGE_GENERIC, "tele-not-allowed")
        return
      }
      
      Gems.Telegram("Clonado com sucesso", "tele-success");
      this.$router.push({ name: "templates" });
    },
  },
  computed: {
    ...mapGetters(["arrTemplate"]),
  },
  mounted() {
    if (history.state.temp_id == undefined)
      this.$router.push({ name: "templates" })

    this.templateId = history.state.temp_id
    this.FetchTemplate(this.templateId)
  },
  beforeRouteLeave() {
    this.resetTemplate()
  },
};
</script>

<style scoped>
@import '../assets/matrix.css';

.tbl {
  width: 100%;
  margin-bottom: 10px;
}

.search {
  margin-left: 10px;
  margin-right: 10px;
}

.tdButtons {
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
  width: 25%;
}

.tdButtons button:last-child {
  margin-top: 5px;
}

.divDesig {
  font-size: 25pt;
  font-weight: bold;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
}

.template-title {
  font-size: 2.4rem;
  border: none;
  width: 100%;
}

.icon {
  margin-left: 5px;
  margin-top: 5px;
}

@media only screen and (max-width: 1400px) {
  .divDesig {
    font-size: 20pt;
  }

  .icon {
    margin-top: 20px;
  }
}
</style>
