<template>
  <div>
    <div v-if="user_id" class="header">
      <UserHeader :userId="user_id" id="myHeader" :matrix="true" />
    </div>

    <div class="content" v-if="Object.keys(summarys).length > 0">
      <div class="div-print">
        <i id="print-report" class="fas fa-print fa-2x invisible cursor-pointer default-app-color" @click="Print"></i>
      </div>
      <div class="inner-header">
        <div class="left">
          <div class="row">
            <span>Ciclo: </span>
            <span class="bold">{{ cycle_name }}</span>
          </div>
          <div class="row">
            <span>Data: </span>
            <span class="bold">{{
              new Date(Date.now()).toLocaleDateString()
            }}</span>
          </div>
        </div>
        <div class="right">
          <div class="evals">
            <div v-if="user_eval.user_integrated_eval != null" class="eval-row">
              <div class="eval-col bold eval-type">INTEGRADA</div>
              <div class="eval-col special-app-color-row div-agg-eval">
                {{ user_eval.user_integrated_eval == '--' ? '--' : Gems.FormatDecimals(user_eval.user_integrated_eval, 2) }}
              </div>
              <div class="eval-col special-app-color-row">
                {{ user_eval.rating_integrated_desig }}
              </div>
            </div>
            <div v-if="user_eval.rating_spec_desig != null" class="eval-row">
              <div class="eval-col bold eval-type">ESPECÍFICA</div>
              <div class="eval-col special-app-color-row div-agg-eval">
                {{ user_eval.user_spec_eval == '--' ? '--' : Gems.FormatDecimals(user_eval.user_spec_eval, 2) }}
              </div>
              <div class="eval-col special-app-color-row">
                {{ user_eval.rating_spec_desig }}
              </div>
            </div>
            <div v-if="user_eval.user_weighted_eval != null" class="eval-row">
              <div class="eval-col bold eval-type">PONDERADA</div>
              <div class="eval-col special-app-color-row div-agg-eval">
                {{ user_eval.user_spec_eval == '--'? '--' : Gems.FormatDecimals(user_eval.user_weighted_eval, 2) }}
              </div>
              <div class="eval-col special-app-color-row">
                {{ user_eval.rating_weighted_desig }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="summary-container">
        <div v-for="(summary, i) in summarys" :key="i" class="summary">
          <p class="team-name bold">
            {{ summary.team_name }} | Peso: {{ Gems.FormatDecimals(summary.team_weight, 2) }}
          </p>
          <div class="scopes-row">
            <div class="scope-desig"></div>
            
            <div v-if="summary.matrix_integrated_eval != null" class="scope-weight bold">INT.</div>
            <div v-else class="scope-weight bold"></div>
            
            <div v-if="summary.matrix_spec_eval != null" class="scope-weight bold">ESP.</div>
            <div v-else class="scope-weight bold"></div>

            <div v-if="summary.matrix_weighted_eval != null" class="scope-weight bold">POND.</div>
            <div v-else class="scope-weight bold"></div>
            
            <div class="scope-weight"></div>
            <div class="scope-weight"></div>
            <div class="scope-weight"></div>
            <div class="scope-auto bold">Auto.</div>
            <div class="scope-eval bold">Avaliação</div>
            <div class="scope-quali"></div>
          </div>
          <div
            class="scopes-row"
            :class="[i % 2 == 0 ? 'special-app-color-row' : '']"
            v-for="(scope, i) in summary['SCOPES']"
            :key="i"
          >
            <div class="scope-desig">{{ scope.sco_desig }}</div>

            <div v-if="scope.integrated_weight != null" class="scope-weight">
              {{ scope.integrated_weight == '--' ? '--' : Gems.FormatDecimals(scope.integrated_weight, 2) }}
            </div>
            <div v-else class="scope-weight"></div>

            <div v-if="scope.spec_weight != null" class="scope-weight">
              {{ scope.spec_weight == '--' ? '--' : Gems.FormatDecimals(scope.spec_weight, 2) }}
            </div>
            <div v-else class="scope-weight"></div>

            <div v-if="scope.weighted_weight != null" class="scope-weight">
              {{ scope.weighted_weight == '--' ? '--' : Gems.FormatDecimals(scope.weighted_weight, 2) }}
            </div>
            <div v-else class="scope-weight"></div>

            <div class="scope-weight"></div>
            <div class="scope-weight"></div>
            <div class="scope-weight"></div>
            <div class="scope-auto">{{ scope.auto_eval ?? "n.a" }}</div>
            <div class="scope-eval">{{ Gems.FormatDecimals(scope.sco_evaluation, 2) }}</div>
            <div class="scope-quali">{{ scope.quali }}</div>
          </div>
          <div :id="`chart${summary.team_id}`" class="chart-container"></div>
        </div>
      </div>

      <div>
        <h3>Informações e Impactos</h3>
        <div class="group greyRow" v-if="impacts.length > 0">
          <div v-for="(elem, i) in impacts" :key="i">
            <div>
              <span>{{ elem.description }}</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3>Prioridades de melhoria</h3>
        <div class="improvements" v-if="Object.keys(to_improve).length > 0">
          <span class="needs-app-name greyRow">KPI</span>
          <div v-for="(ind, i) in to_improve?.kpi" :key="i">
            <div class="ind-kpi" :class="RowClass(i)">
              <span class="desig"
                >{{ ind.ind_scope }} | {{ ind.ind_desig }}</span
              >
              <!-- <span class="evaluation">{{ Gems.FormatDecimals(ind.ind_evaluation, 2) }}</span> -->
            </div>
          </div>

          <br />
          <div
            v-if="
              has_comp &&
              (to_improve?.comp?.skills?.length > 0 ||
                to_improve?.comp?.behaviours?.length > 0)
            "
          >
            <span class="needs-app-name greyRow">COMP</span>
            <div v-for="(ind, i) in to_improve?.comp?.skills" :key="i">
              <div class="ind-kpi" :class="RowClass(i)">
                <span>Competência | {{ ind.description }}</span>
              </div>
            </div>

            <div v-for="(ind, i) in to_improve?.comp?.behaviours" :key="i">
              <div class="ind-kpi" :class="RowClass(i)"> 
                <span>Comportamento | {{ ind.description }}</span>
              </div>
            </div>
          </div>

          <br />

          <div v-if="has_360 && to_improve['360'].length > 0">
            <span class="needs-app-name greyRow">360</span>
            <div v-for="(ind, i) in to_improve['360']" :key="i">
              <div class="ind-kpi" :class="RowClass(i)">
                <span
                  >Interação/Desempenho enquanto | {{ ind.description }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h3>Necessidades de Formação</h3>
          <div class="improvements">
            <div class="div-input-training-needs" v-if="!mySummary">
              <input type="text" class="input-training-needs" ref="trainingNeed" maxlength="100">
              <i id="send-training-need" class="fas fa-share-square icon-training-needs cursor-pointer default-app-color" @click="SendTrainingNeed"></i>
            </div>
            <div v-if="training_needs.length > 0">
              <div v-for="(ind, i) in training_needs" :key="i">
                <div class="row-training-needs" :class="RowClass(i)">
                  <span class="training-desig">{{ ind.description }}</span>
                  <i class="far fa-minus-square icon-rm-training-needs cursor-pointer default-app-color"  @click="RemoveTrainingNeed(ind.id)" @mouseenter="Gems.Tooltip($event.target, 'Remover', 'tooltip')" v-if="!mySummary"></i>
                </div>
              </div>
            </div>
          </div>
      </div>

      <div style="width: 50%" class="inline">
        <h3 style="text-align: left" class="columnHeader">COMENTÁRIO</h3>
      </div>
      <div style="width: 50%" class="inline">
        <h3 style="text-align: left" class="columnHeader">
          OBJETIVOS & COMPROMISSOS
        </h3>
      </div>

      <div style="width: 50%" class="inline">
        <textarea
          id="txtComment"
          style="width: 95%"
          rows="4"
          maxlength="1000"
          required
        ></textarea>
      </div>
      <div style="width: 50%" class="inline">
        <textarea
          id="txtGoals"
          style="width: 99%"
          rows="4"
          maxlength="1000"
          required
        ></textarea>
      </div>

      <div style="width: 100%; text-align: right">
        <button
          id="btnSave"
          class="btn-save"
          @click="SaveGoals()"
        >
          Publicar Comentários/Objetivos
        </button>
      </div>

      <section v-if="comments.length > 0">
        <div id="divTitles" class="divTitles">
          <div style="width: 50%" class="inline">
            <div style="text-align: left; margin-left: 5px" class="">
              COMENTÁRIOS
            </div>
          </div>
          <div style="width: 49%" class="inline">
            <div style="text-align: left; margin-left: 9px" class="">
              OBJETIVOS
            </div>
          </div>
        </div>

        <div
          id="divGoals"
          style="text-align: left"
          v-for="(comment, i) in comments"
          :key="i"
        >
          <div class="divAuthor">
            <div class="divHeader">
              <div class="divCommentAuthor">{{ comment.evaluator }}</div>
              <div class="divCommentDate">{{ comment.date }}</div>
            </div>
            <button
              class="deleteMessageButton"
              @click="DeleteComment(comment.comment_id)"
            >
              APAGAR
            </button>
          </div>
          <div class="divGoalsWrapper">
            <div class="divGoalsLeft">{{ comment.comments }}</div>
            <div class="divGoalsRight">{{ comment.goals }}</div>
          </div>
        </div>
      </section>
    </div>
    <div v-else-if="noSummary">
      <span class="no-matrix">Sem dados</span>
    </div>
  </div>
</template>

<script>
import { KPI } from "../kpi";
import UserHeader from "../components/UserHeader";
import { Gems } from "../gems";
import Chart from "chart.js/auto";

export default {
  name: "reports",
  components: {
    UserHeader,
  },
  data() {
    return {
      training_needs: [],
      summarys: [],
      mySummary: Boolean,
      user_eval: [],
      to_improve: [],
      comments: [],
      impacts: [],
      has_360: false,
      has_comp: false,
      user_id: "",
      cycle_name: "",
      noSummary: false,
      Gems,
    };
  },
  methods: {

    RowClass(row) {
      return row % 2 == 0 ? "special-app-color-row" : "";
    },

    async GetSummary(userId) {
      const response = await KPI.CallBackEnd("get", "get-summary", {
        params: {
          cycle_id: localStorage.getItem("cycle"),
          user_id: userId,
        },
      })

      this.user_id = response.data["user_id"];
      this.mySummary = sessionStorage.getItem('logged_user_id') == this.user_id;
      this.cycle_name = response.data["cycle_name"];

      if (response.data["summary"]) {
        this.user_eval = response.data["user_evaluation"];
        this.summarys = response.data["summary"];
        this.comments = response.data["comments"];
        this.impacts = response.data["impacts"];
        this.to_improve = response.data["to_improve"];
        this.has_360 = response.data["has_360"];
        this.has_comp = response.data["has_comp"];
        this.training_needs = response.data["training"];
      } else {
        this.noSummary = true;
      }

    },

    DeleteChartIfExists() {
      for (let summary in this.summarys) {
        const chart = document.getElementById(
          `chart${this.summarys[summary]["team_id"]}`
        );
        if (chart) 
          chart.innerHTML = "";
      }
    },

    GenerateChart() {
      this.DeleteChartIfExists();

      for (let summary in this.summarys) {
        let dataset = {
          labels: ["EMPRESA", "EQUIPA", "INDIVIDUAL", "COMP", "360"],
          datasets: [],
        };

        let scope_evals = [];
        for (let scope in this.summarys[summary]["SCOPES"]) {
          scope_evals.push(
            this.summarys[summary]["SCOPES"][scope]["sco_evaluation"]
          );
        }

        let dt0 = {
          label: "Parciais",
          data: scope_evals,
          borderColor: "rgb(153, 102, 255)",
          backgroundColor: "rgba(153, 102, 255, 0.2)",
          borderWidth: 1,
        };

        dataset["datasets"].push(dt0);

        if (
          this.summarys[summary]["matrix_integrated_eval"] != null &&
          this.summarys[summary]["matrix_integrated_eval"] != "--"
        ) {
          let dt1 = {
            label: "Integrada",
            data: [
              this.summarys[summary]["matrix_integrated_eval"],
              this.summarys[summary]["matrix_integrated_eval"],
              this.summarys[summary]["matrix_integrated_eval"],
              this.summarys[summary]["matrix_integrated_eval"],
              this.summarys[summary]["matrix_integrated_eval"],
            ],
            borderColor: "black",
            backgroundColor: "black",
            type: "line",
          };
          dataset["datasets"].push(dt1);
        }

        if (
          this.summarys[summary]["matrix_spec_eval"] != null &&
          this.summarys[summary]["matrix_spec_eval"] != "--"
        ) {
          let dt2 = {
            label: "Específica",
            data: [
              this.summarys[summary]["matrix_spec_eval"],
              this.summarys[summary]["matrix_spec_eval"],
              this.summarys[summary]["matrix_spec_eval"],
              this.summarys[summary]["matrix_spec_eval"],
              this.summarys[summary]["matrix_spec_eval"],
            ],
            borderColor: "grey",
            backgroundColor: "grey",
            borderDash: [10],
            type: "line",
          };
          dataset["datasets"].push(dt2);
        }

        if (
          this.summarys[summary]["matrix_weighted_eval"] != null &&
          this.summarys[summary]["matrix_weighted_eval"] != "--"
        ) {
          let dt3 = {
            label: "Ponderada",
            data: [
              this.summarys[summary]["matrix_weighted_eval"],
              this.summarys[summary]["matrix_weighted_eval"],
              this.summarys[summary]["matrix_weighted_eval"],
              this.summarys[summary]["matrix_weighted_eval"],
              this.summarys[summary]["matrix_weighted_eval"],
            ],
            borderColor: "blue",
            backgroundColor: "blue",
            borderDash: [2],
            type: "line",
          };
          dataset["datasets"].push(dt3);
        }
        let newChart = document.createElement("canvas");
          
        const chart = document.getElementById(`chart${this.summarys[summary]["team_id"]}`)
        if (chart) chart.appendChild(newChart);

        new Chart(newChart, {
          type: "bar",
          data: dataset,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                displayColors: false,
                callbacks: {
                  title: (context) =>
                    context[0]["datasetIndex"] == 0
                      ? context[0]["label"]
                      : "KPI",
                  
                  label: (context) => {return Gems.FormatDecimals(context.formattedValue, 2)}
                },
              },
            },
            scales: {
              x: {
                stacked: true,
                beginAtZero: true,
                max: 100,
                min: 0,
                ticks: {
                  font: {
                    weight: "bold",
                  },
                },
              },
              y: {
                stacked: false,
                beginAtZero: true,
                max: 100,
                min: 0,
              },
            },
          },
        });
      }
    },

    async SaveGoals() {
      Gems.Telegram(
        "Submeter novos comentários e objetivos?",
        "tele-info",
        "",
        ""
      ).then(async () => {
        const txtComment = document.getElementById("txtComment");
        const txtGoals = document.getElementById("txtGoals");
        txtComment.classList.remove("error-outline");
        txtGoals.classList.remove("error-outline");

        if (txtComment.value != "" && txtGoals.value != "") {
          var arrGoals = {
            comment: document.getElementById("txtComment").value,
            goals: document.getElementById("txtGoals").value,
          };

          const response = await KPI.CallBackEnd("post", "insert-goals", {
            arr: arrGoals,
            cycle_id: localStorage.getItem("cycle"),
            user_id: this.user_id,
          });

          if (response.status && !response.error) {
            
            await this.GetComments();

            Gems.Telegram("Gravado com sucesso", "tele-success");
            
            txtComment.value = "";
            txtGoals.value = "";
          } else {
            Gems.Telegram(
              "Erro ao gravar comentários/objetivos e compromissos",
              "tele-not-allowed"
            );
          }
        } else {
          txtComment.classList.add("error-outline");
          txtGoals.classList.add("error-outline");
          Gems.Telegram("Campos não preenchidos", "tele-not-allowed");
        }
      });
    },

    async DeleteComment(id) {
      Gems.Telegram(
        "Deseja apagar o comentário? (Operação irreversível)",
        "tele-info",
        "",
        ""
      ).then(
        async () => {
          const response = await KPI.CallBackEnd(
            "post",
            "delete-comment-summary",
            {
              id,
            }
          );

          if (response.status && !response.error) {
            await this.GetComments();
            Gems.Telegram("Comentários apagados", "tele-success");
          }
        },
        () => {}
      );
    },

    Print() {
      window.print();
    },

    async SendTrainingNeed () {
      const response = await KPI.CallBackEnd("post", "training-needs-control", {
        params:{
          cycle_id: localStorage.getItem("cycle"),
          user_id: this.user_id,
          description: this.$refs.trainingNeed.value,
          action: "insert"
        }
      })
      if (response.data.error) {
        return Gems.Telegram("Ocorreu um erro", "tele-not-allowed");
      }
      Gems.Telegram("Gravado", "tele-success");
      this.GetTrainingNeeds();
      this.$refs.trainingNeed.value = "";
    },

    async GetTrainingNeeds() {
      const response = await KPI.CallBackEnd("post", "training-needs-control", {
        params:{
          cycle_id: localStorage.getItem("cycle"),
          user_id: this.user_id,
          action: "get"
        },
      })
      this.training_needs = response.data;
    },

    async RemoveTrainingNeed(id) {
      const response = await KPI.CallBackEnd("post", "training-needs-control", {
        params:{
          id,
          action: "remove"
        }
      })
      if (response.data.error) {
        return Gems.Telegram("Ocorreu um erro", "tele-not-allowed");
      }
      Gems.Telegram("Removido", "tele-success");
      this.GetTrainingNeeds();
    },

    async GetComments() {

      const response = await KPI.CallBackEnd("get", 'get-operations', {
        params: {
          get: "summary-comments",
          user_id: this.user_id,
          cycle: localStorage.getItem('cycle')
        }
      })

      if (response.data.error) {
        Gems.Telegram("Ocorreu um erro", "tele-not-allowed")
        return
      }

      this.comments = response.data;
    },

    ControlTooltips() {
      const printBtn = document.getElementById("print-report");
      if (printBtn) Gems.Tooltip(printBtn, "Imprimir", "tooltip");
      
      const sendTrainingBtn = document.getElementById(`send-training-need`);
      if (sendTrainingBtn) Gems.Tooltip(sendTrainingBtn, "Enviar", "tooltip");

    },
  },
  created() {
    if (history.state.userId) {
      this.user_id = history.state.userId;
    } else {
      this.user_id = null;
    }
    this.GetSummary(this.user_id);
  },
  mounted() {
    this.ControlTooltips();
  },
  updated() {
    this.ControlTooltips();
    this.GenerateChart();
  }
};
</script>

<style scoped>

.header {
  height: 120px;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 1041px;
}

.sticky + .content {
  padding-top: 110px;
}

.content {
  padding: 10px;
}

.inner-header {
  display: flex;
  justify-content: space-between;
}

.summary-container {
  margin: 20px 0px;
}

.summary {
  margin-bottom: 20px;
}

.team-name {
  margin: 0;
}

.right,
.left {
  display: flex;
}

.left {
  flex-direction: column;
}

.evals {
  display: flex;
  flex-direction: column;
}

.eval-title {
  font-weight: bold;
}

.eval-row {
  text-align: center;
  justify-content: space-evenly;
  width: 250px;
  display: grid;
  grid-template-columns: 37% 26% 37%;
  border-bottom: 1px solid white;
}
.div-agg-eval {
  border-right: 1px solid white;
}

.bold {
  font-weight: 600;
}

.scopes-row {
  display: flex;
}

.scope-id {
  width: 5%;
  color: var(--medium-gray-color);
  /* background-color: white; */
  text-align: center;
}

.scope-desig {
  width: 30%;
}

.scope-quali {
  width: 10%;
}

.scope-weight {
  width: 7%;
}

.scope-auto {
  width: 10%;
}

.scope-eval {
  width: 10%;
}

.ind-kpi {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px 5px 15px;
}

.desig {
  width: 95%;
}

.evaluation {
  width: 5%;
  text-align: left;
}

.group {
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 20px;
}

.improvements {
  margin-bottom: 20px;
  background-color: transparent !important;
}

.mid {
  width: 50%;
}

.full {
  width: 98%;
}

.needs-app-name {
  font-weight: bold;
  display: block;
  padding: 7px;
  background-color: var(--medium-gray-color);
}

.divTitles {
  margin-top: 10px;
  font-weight: bold;
  font-size: 10pt;
}
.btn-save {
  width: 200px; 
  margin: 10px 0px;
  text-transform: uppercase;
}

.inline {
  display: inline-block;
  vertical-align: middle;
}

.columnHeader {
  text-align: center;
  margin: 0% 0% 2% 1%;
  font-weight: bold;
}

#txtComment {
  height: 100px;
  resize: vertical;
}

#txtGoals {
  height: 100px;
  resize: vertical;
}

.div-header {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
}

.divGoalsWrapper {
  background-color: var(--light-gray-color);
  margin-bottom: 20px;
  display: flex;
}

.deleteMessageButton {
  width: 100px !important;
  height: 35px !important;
  font-size: 8pt !important;
}

.divGoalsLeft {
  width: 48%;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  font-size: 0.9em;
}

.divGoalsRight {
  border-left: solid 4px #fff;
  width: 47%;
  display: inline-block;
  vertical-align: top;
  padding: 10px;
  font-size: 0.9em;
}

.divAuthor {
  background-color: var(--light-gray-color);
  margin: 10px 0px 4px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.divCommentDate {
  padding-top: 1px;
  font-size: 9pt;
}

.divCommentAuthor {
  padding-bottom: 1px;
  font-size: 11pt;
  font-weight: bold;
}

.no-matrix {
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  min-height: 250px;
}

.chart-container {
  height: 350px;
  margin: 20px auto;
}

.div-print {
  margin-bottom: 10px;
  text-align: right;
}

.training-desig {
  width: 95%;
  padding-left: 15px;
}

.div-input-training-needs, .row-training-needs {
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.div-input-training-needs {
  background-color: var(--light-gray-color);
  padding: 4px;
}

.row-training-needs {
  margin-bottom: 2px;
  align-items: center;
}
.input-training-needs {
  width: 95%;
}
.btn-training-needs {
  width: 5%;
  height: 100%;
}

.icon-rm-training-needs {
  transform: scale(2.5);
  height: fit-content;
  width: fit-content;
  font-size: unset;
  margin-right: 11px;
}

.icon-training-needs {
  transform: scale(2.5) rotate(180deg);
  width: fit-content;
  height: fit-content;
  font-size: unset;
  margin-top: 11px;
  margin-right: 10px;
}

.eval-type {
  text-align: right;
}

@media print {
  .div-print {
    display: none;
  }

  .content {
    width: 1100px;
  }

  .header {
    width: 1110px;
  }
}

@media only screen and (max-width: 1400px) {
  .sticky {
    width: 833px;
  }

  .scope-auto {
    width: 12%;
  }

  .scope-desig {
    width: 35%;
  }
}
</style>
