<template>
  <table class="tbl-indicators">
    <thead class="thead-indicators ind_color_2">
      <tr>
        <td></td>
        <td class="td-id"></td>
        <td class="td-desig">Indicador</td>
        <td class="td-weight">Peso</td>
        <td class="td-metric">Métrica</td>
        <td class="td-unit">Unid.</td>
        <td class="td-goal">Objetivo</td>
        <td class="td-mark">Valor crítico</td>
        <td class="td-vol">Resultado</td>
        <td class="td-realization">Realização</td>
        <td class="td-realization">Avaliação</td>
        <td class="tdAbove100">> 100%</td>
      </tr>
    </thead>

    <tbody
      :class="[row_count++ % 2 == 0 ? 'greyRow' : 'special-app-color-row']"
      :key="indicator.ind_id"
      v-for="(indicator, row_count) in arrIndicators"
    >
      <MatrixIndicatorBlock
        :objIndicator="indicator"
        :scopeId="2"
      />
    </tbody>
  </table>
</template>

<script>
import MatrixIndicatorBlock from "./MatrixIndicatorBlock.vue"

export default {
  name: "TeamsIndicatorList",
  props: {
    arrIndicators: Array,
  },
  components: {
    MatrixIndicatorBlock
  },
};
</script>

<style scoped>

.td-unit {
  width: 4%;
}

.td-goal {
  width: 9%;
}

.td-mark {
  width: 9%;
}

.td-result {
  width: 10%;
}
</style>
