export const useLocalStorage = () => {

    function getItem(itemName) {
        return JSON.parse(localStorage.getItem(itemName))
    }

    const admin = getItem('admin').value == 1
    const cycleId = getItem('cycle')
    const cycleFinished = getItem('cycle_finished')

    return { admin, cycleId, cycleFinished }
}