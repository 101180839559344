<template>
  <tr>
    <td class="tdScope">{{ objScope.sco_desig }}</td>
    <td>{{ objScope.sco_weight != '-' ? Gems.FormatDecimals(objScope.sco_weight, 2) : '--' }}</td>
  </tr>
</template>

<script>
import { Gems } from '../gems';

export default {
  name: "TemplatesPreviewScope",
  props: {
    objScope: Object,
  },
  data(){
    return {
      Gems,
    }
  },
};
</script>

<style scoped>
.tdScope {
  width: 100%;
  text-align: left;
}
</style>
