<template>
  <div class="div-admin" v-if="$router.currentRoute._value.path === '/admin'">
    <input
      class="inputfile cursor-pointer"
      id="file"
      type="file"
      ref="file"
      @change="SelectFile"
    />
    <label for="file" class="import-logo cursor-pointer" ref="logo">
      <img :src="src" v-if="preview == true" />
      <i
        class="fab fa-linode fa-4x"
        aria-hidden="true"
        v-show="preview == false"
      ></i>
    </label>
    <button
      class="logo-btn"
      @click="UpdateData"
      ref="button"
      v-show="preview == true"
    >
      CARREGAR LOGOTIPO
    </button>
    <div class="buttons-admin">
      <router-link to="/cycle">
        <button>GERIR CICLOS</button>
      </router-link>
      <router-link to="/import">
        <button>IMPORTAR / EXPORTAR</button>
      </router-link>
      <router-link to="/templates">
        <button>GERIR MODELOS</button>
      </router-link>
      <router-link to="/teams">
        <button>GERIR EQUIPAS</button>
      </router-link>
      <router-link to="/permissions">
        <button>GERIR PERMISSÕES</button>
      </router-link>
      <button @click="GetResultsMap()">MAPA DE RESULTADOS</button>
      <router-link to="/results">
        <button>CARREGAR RESULTADOS</button>
      </router-link>
      <router-link to="/manage-metrics">
        <button>GERIR MÉTRICAS</button>
      </router-link>
      <router-link to="/app-report">
        <button class="help">RELATÓRIO DE SISTEMA</button>
      </router-link>
      <router-link to="/report-errors">
        <button class="help btn-action">HELP</button>
      </router-link>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";
import { mapActions } from "vuex";

export default {
  name: "admin",
  emits: ["force-reload"],
  data() {
    return {
      file: null,
      fileUser: "",
      name: null,
      src: String,
      preview: false,
      cycle: "",
    };
  },
  methods: {
    ...mapActions(["ReloadMenu"]),

    SelectFile(e) {
      this.file = e.target.files[0];
      this.name = KPI.Cyrb53(`${Date.now()}`, 5);
      this.src = URL.createObjectURL(this.file);
      this.preview = true;
    },

    async UpdateData() {
      if (this.file !== null) {
        const formData = new FormData();
        formData.append("file", this.$refs.file.files[0]);
        formData.append("name", this.name);
        formData.append("admin", "true");

        const response = await KPI.CallBackEnd("post", "upload-image", formData)

        if(response.data?.error) {
          Gems.Telegram(response.data.message, 'tele-not-allowed')
          return
        }
      }

      const logo = this.name + '.' + this.file.name.slice((this.file.name.lastIndexOf(".") - 1 >>> 0) + 2)
      
      await KPI.CallBackEnd("post", "set-operations", {
        params: {
          set: "company-logo",
          logo,
        },
      })
      this.$emit("force-reload")
    },

    async GetResultsMap() {
      Gems.Telegram("Certifique-se de que o seu browser não está a bloquear o download", "tele-success" )

      const response = await KPI.CallBackEnd("post", "export", {
        cycle: localStorage.getItem("cycle"),
        type: 5,
      })

      if(response.data.error){
        Gems.Telegram(response.data.message, 'tele-not-allowed')
        return
      }

        if (response.data)
          Gems.ExportExcel(response.data);
    },
  },
  mounted() {
    if (this.$refs.logo) {
      Gems.Tooltip(this.$refs.logo, "Logotipo da empresa", "tooltip");
    }
    this.cycle = localStorage.getItem("cycle");
  },
  updated() {
    if (this.$refs.logo) {
      Gems.Tooltip(this.$refs.logo, "Logotipo da empresa", "tooltip");
    }
  },
};
</script>

<style scoped>

.div-admin {
  margin: 16px auto 16px auto;
  flex-direction: column;
  display: flex;
}

.div-admin button {
  width: 22rem;
  height: 5rem;
  margin: 0.1%;
  font-size: 1em !important;
}

.buttons-admin {
  text-align: center;
}

.import-logo {
  height: 165px;
  width: 300px;
  background-color: var(--medium-gray-color);
  margin: 10px auto 50px auto;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--font-color);
}

.import-logo img {
  max-width: 100%;
}

.logo-btn {
  margin: 0 auto !important;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.125em;
}

</style>
