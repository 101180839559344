<template>
  <div>
    <div class="div-inline">
        <select class="select-indicator" @change="OpenGrid" v-model="indicatorSelectedIndex">
          <option v-for="(indicator, i) in indicatorsList" :value="i" :key="i">
            {{ indicator.designation }}
          </option>
          
        </select>

        <div class="tabs">
          <label for="interval" class="label-grow">
            <div id="interval-tab" class="interval-tab tab cursor-pointer">
                <input type="radio" name="tabs" id="interval" :checked="directMatching == 0" @change="(ev)=>{if(ev.target.checked) OpenMetricTypeTab(0)}">
                <label>Intervalos</label>
            </div>
          </label>
          <label for="direct" class="label-grow">
            <div id="direct-tab" class="direct-tab tab cursor-pointer">
                <input type="radio" name="tabs" id="direct" :checked="directMatching == 1"  @change="(ev)=>{if(ev.target.checked) OpenMetricTypeTab(1)}">
                <label>Correspondência direta</label>
            </div>
          </label>
        </div>
    </div>

    <div class="metric" v-show="indicatorSelectedIndex">
      <div class="tab-content">
        <table class="content-table">
          <thead class="content-header">
            <th class="th-option-value" v-if="directMatching == 0" ref="optionValue"
              @mouseenter="Gems.TooltipIfNeeded($refs.optionValue)">
              Valor {{ selectedUnit }}
            </th>
            <th class="th-option-value-direct-type" v-else>
              Opções de resultado {{ selectedUnit }}
            </th>
            <th class="th-evaluation">Avaliação (%)</th>
            <th class="th-legend" :class="directMatching == 1 ? 'th-tooltip-direct-type' : 'th-tooltip'">
              <div class="div-align-btn"> Legenda </div>
              <div>
                <button class="btn btn-action" @click="Save()">GRAVAR</button>
              </div>
            </th>
            <th class="th-minus">
              <button class="btn btn-add-line" @click="AddMetricTableElement()" ref="plus">+</button>
            </th>
          </thead>

          <tbody>
            <tr v-for="(row, index) in gridData" :key="index" class="content-row">
              <td
                :class="
                  directMatching == 1
                    ? 'td-option-value-direct-type'
                    : 'td-option-value'
                "
              >
                <input
                  type="text"
                  v-model="row.option_value"
                  :placeholder="row.option_value"
                  class="txtLeft"
                  ref="interval"
                  maxlength="20"
                />
              </td>
              <td class="td-evaluation">
                <input
                  type="text"
                  v-model="row.evaluation"
                  :placeholder="row.evaluation"
                  class="txtRight"
                  ref="evaluation"
                />
              </td>
              <td
                :class="
                  directMatching == 1 ? 'td-tooltip-direct-type' : 'td-tooltip'
                "
              >
                <input
                  type="text"
                  v-model="row.tooltip"
                  :placeholder="row.tooltip"
                  class="txtRight"
                  ref="tooltipText"
                />
              </td>
              <td class="td-minus">
                <button
                  class="btn-remove-line"
                  ref="minus"
                  @click="RemoveMetricTableElement(index)"
                >
                  -
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
  
<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "manage-metrics",
  emits: ["force-reload"],
  data() {
    return {
      indicatorsList: [{ designation: "- Escolha o indicador -" }],
      indicatorSelected: Array,
      indicatorSelectedIndex: Number,
      gridData: [],
      deletedGridData: [],
      directMatching: 1,
      selectedUnit: "",
      Gems,
    };
  },
  methods: {
    async getIndicatorsTableMetric() {
      const response = await KPI.CallBackEnd("get", "get-operations", {
        params: {
          get: "get-table-metrics",
        },
      });

      this.indicatorSelectedIndex = 0;
      if (response.data.length > 0) {
        this.indicatorsList.push(...response.data);
      }
    },

    OpenGrid() {
      if (this.indicatorSelectedIndex == 0) return;

      this.indicatorSelected = this.indicatorsList[this.indicatorSelectedIndex];
      this.deletedGridData = [];
      this.gridData = this.indicatorSelected.grid_data;
      this.selectedUnit = `(${this.indicatorSelected.unit})`;
      this.directMatching =
        this.gridData.length > 0 ? this.indicatorSelected.direct_matching : 1;
    },

    AddMetricTableElement() {
      if (this.gridData.length == 9) {
        Gems.Telegram(
          "Impossível Adicionar mais do que 10 escalas!",
          "tele-not-allowed"
        );
        return;
      }

      this.gridData.push({
        indicator_id: this.indicatorSelected.id,
        option_value: null,
        interval_max: null,
        evaluation: "",
        tooltip: "",
      });
    },

    RemoveMetricTableElement(index) {
      this.deletedGridData.push(this.gridData.splice(index, 1));
    },

    async Save() {
      if (this.indicatorSelectedIndex == 0) return;

      if (
        this.gridData.filter((row)=>!this.CheckNumber(row.option_value)).length != 0
      ) {
        return;
      }

      if (this.directMatching == 0) {
        for (let i in this.gridData) {
          this.gridData[i]["interval_max"] =
            parseInt(i) + 1 == this.gridData.length
              ? 100
              : this.gridData[parseInt(i) + 1]["option_value"];
        }
      }
      this.indicatorSelected.direct_matching = this.directMatching;
      this.indicatorSelected.grid_data = this.gridData;

      const response = await KPI.CallBackEnd("post", "save-metric-grid", {
        indicator: this.indicatorSelected,
      })

      if(!response.error)
        Gems.Telegram(response.data.message, "tele-success")

    },

    CheckNumber(value) {
      if (!(Number.isNaN(value) !== true) && this.directMatching == 0) {
        var rule = "/^[0-9]+$/"
        if (value.match(rule)) {
          return true
        } else {
          Gems.Telegram("Valor numérico", "tele-not-allowed")
          return false
        }
      }
      return true
    },

    OpenMetricTypeTab(directMatching) {
      this.directMatching = directMatching;
    },
  },
  mounted() {
    this.getIndicatorsTableMetric();
  },
  updated() {
    if (this.$refs.minus)
      this.$refs.minus.map((elem) =>
        Gems.Tooltip(elem, "Remover", "tooltip")
      );

    if (this.$refs.plus)
      Gems.Tooltip(this.$refs.plus, "Nova linha", "tooltip");

    if (this.$refs.interval)
      this.$refs.interval.map((elem) =>
        Gems.Tooltip(elem, "Campo numérico", "tooltip")
      );

    if (this.$refs.metric)
      this.$refs.metric.map((elem) =>
        Gems.Tooltip(elem, "Campo numérico", "tooltip")
      );

    if (this.$refs.evaluation)
      this.$refs.evaluation.map((elem) =>
        Gems.Tooltip(elem, "Campo numérico", "tooltip")
      );
  },
};
</script>
  
<style scoped>

.metric {
  margin-top: 20px;
}

.tabs {
  display: flex;
  flex-grow: 1;
  width: 45%;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab input {
  margin: 0px 5px 0px 0px;
}

.none {
  display: none;
}

.active {
  opacity: 1 !important;
  background-color: white !important;
  border: none !important;
}

.interval-tab,
.direct-tab {
  flex: 1;
  height: 100%;
  text-align: center;
  font-weight: bold;
}

.interval-tab {
  border-right: none;
}
.direct-tab {
  border-left: none;
}

.content-table {
  display: block;
}

.content-table tbody {
  display: block;
}

.content-header,
.content-row {
  display: flex;
}

.btn-remove-line {
  width: -webkit-fill-available;
  height: 35px;
  font-size: 1.5em !important;
}

.btn-add-line {
  width: 50px !important;
  font-size: 1.5em !important;
}

.btn {
  width: 8rem;
  height: 35px;
}

.intervals {
  display: flex;
  margin-top: 5px;
}

.td-option-value,
.th-option-value {
  width: 10%;
}

.th-option-value {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  cursor: default;
}

.td-option-value-direct-type,
.th-option-value-direct-type {
  width: 20%;
}

.td-evaluation,
.th-evaluation {
  width: 10%;
}

.td-tooltip,
.th-tooltip {
  width: 74%;
}

.td-tooltip-direct-type,
.th-tooltip-direct-type {
  width: 64%;
}

.td-minus,
.th-minus {
  width: 5%;
}

.txtRight,
.txtLeft {
  width: -webkit-fill-available;
  height: 23px;
}

.indicator-ddl-label {
  font-weight: bold;
  margin-left: 15px;
}

.select-indicator {
  width: 85%;
  height: 35px;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 2px;
}

.td-save {
  text-align: end;
}

.label-grow {
  flex: 1;
}

.div-inline {
  display: flex;
  width: -webkit-fill-available;
}

.th-legend {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.content-header {
    align-items: center;
    background-color: var(--special-app-color);
}

.div-align-btn {
    flex: 1;
}

@media only screen and (max-width: 1400px){

  .td-evaluation, 
  .th-evaluation {
    width: 11%;
  }

  .td-tooltip-direct-type,
  .th-tooltip-direct-type {
    width: 62%;
  }
}
</style>
