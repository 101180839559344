<template>
  <div class="div-indicator-radio">
    <input
      type="radio"
      @click="$emit('ChangeRadio', IsAffirmativeMetric() ? 1 : 0)"
      :checked="objIndicator.ind_vol == (IsAffirmativeMetric() ? 1 : 0)"
      :disabled="disabled"
    />Sim
    <input
      type="radio"
      @click="$emit('ChangeRadio', IsAffirmativeMetric() ? 0 : 1)"
      :checked="objIndicator.ind_vol == (IsAffirmativeMetric() ? 0 : 1)"
      :disabled="disabled"
    />Não
  </div>
</template>

<script>
export default {
    name: "IndicatorBooleanVolume",
    emits: ["ChangeRadio"],
    props: {
        objIndicator: Object,
        disabled: Boolean
    },
    methods: {
      IsAffirmativeMetric() {
        return this.objIndicator.ind_met_id == 5
      }
    }
}
</script>

<style scoped>
  .div-indicator-radio {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    margin-top: 0;
    cursor: pointer;
  }
</style>