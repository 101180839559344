<template>
  <!-- <div ref="throbber" class="modalT icoThrobber"></div> -->
  <div class="frame" v-if="show === true">
    <div class="header">
      <UserHeader :userId="typeof UserIdState == 'function' ? null : UserIdState" id="myHeader" :matrix="true" />
    </div>

    <div class="content">
      <div v-for="(tab) in Matrix" :key="tab" class="tab-names-header">
        <MatrixInsideHeader
          :arrScopes="tab"
          :evalType="typeof GetEvaluationSelected == 'function' ? '' : GetEvaluationSelected"
          :permission="typeof permission == 'function' ? false : permission"
          :id="`${tab.team_id}header`"
          :class="Object.keys(Matrix).length > 1 ? 'tab-header' : ''"
          :rendered="rendered"
          :countMatrix="Object.keys(Matrix).length"
        />
      </div>

      <div class="tab" :class="Object.keys(Matrix).length > 1 ? 'cursor-pointer' : ''">
        <div v-for="(tab, index) in Matrix" :key="tab" class="tablinks" @click="ChangeCards($event, tab.team_id)" :id="`matrix` + tab.team_id">
          <div class="div-left">
            <p class="line-clamp-2" :ref="`team${index}`" @mouseenter="Gems.TooltipIfNeeded($refs[`team${index}`][0])">{{ tab.team_name }}</p>
          </div>

          <div>
            <div class="input-weight">
              <p>Avaliação: </p>
              <p class="p-eval">{{ GetMatrixEvaluationType(tab) }} %</p>
            </div>

            <div class="input-weight">
              <p>Peso: </p>
              <input type="text" class="input p-weight" maxlength="4" @change="Update(tab)" v-model="tab.team_weight" :disabled="cycleFinished || !permission"/>
            </div>
          </div>
        </div>
      </div>

      <div v-for="(tab, index) in Matrix" :key="index">
        <div
          :class="Object.keys(Matrix).length > 1 ? 'tabcontent' : ''"
          :id="tab.team_id"
        >
          <MatrixScopeList
            :arrScopes="tab"
            :teamId="Number(tab.team_id)"
            :show="true"
            :evalType="typeof GetEvaluationSelected == 'function' ? '' : GetEvaluationSelected"
            :permission="typeof permission == 'function' ? false : permission"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="frame" v-else-if="noMatrix == true">
    <div class="header">
      <UserHeader
        :userId="typeof UserIdState == 'function' ? null : UserIdState"
        id="myHeader"
        :matrix="true"
      />
    </div>
    <span class="no-matrix"> Nenhuma matriz encontrada </span>
  </div>

  <Modal
    :releases="true"
    :activate="activateModal"
    :releasesArr="releasesArr"
    @update-releases="UpdateReleases($event)"
  />
</template>

<script>
import MatrixScopeList from "../components/MatrixScopeList";
import Modal from "../components/Modal";
import UserHeader from "../components/UserHeader";
import MatrixInsideHeader from "../components/MatrixInsideHeader.vue";
import { mapGetters, mapActions } from "vuex";
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "matrix",
  emits: ["force-reload"],
  props: {
    userId: String,
    teamId: String,
    releasesArr: [Array, Object],
    activateModal: Boolean,
  },
  components: {
    MatrixScopeList,
    UserHeader,
    MatrixInsideHeader,
    Modal,
  },
  data() {
    return {
      scopes: Array,
      rendered: false,
      permission: String,
      show: false,
      noMatrix: false,
      admin: false,
      cycleFinished: false,
      Gems,
    };
  },
  methods: {
    ThrobberON() {
      this.$refs.throbber.style.visibility = "visible";
    },

    ThrobberOFF() {
      this.$refs.throbber.style.visibility = "hidden";
    },

    ...mapActions([
      "FetchMatrix",
      "ResetMatrix",
      "StoreUserId",
      "SaveMatrix",
      "UserEvaluation",
    ]),

    async Update() {
      this.UserEvaluation()
    },

    GetMatrixEvaluationType(tab) {
      if(this.GetEvaluationSelected == 1)
        return tab.matrix_integrated_eval

      if(this.GetEvaluationSelected == 2)
        return tab.matrix_spec_eval

      if(this.GetEvaluationSelected == 3)
        return tab.matrix_weighted_eval

      return 0
    },

    UpdateReleases(max_release) {
      KPI.CallBackEnd("post", "update-releases", {
        max_release,
      });
    },

    ChangeCards(evt, cardId) {
      for (let tab in this.Matrix) {
        if (this.Matrix[tab]["team_id"] == cardId) {
          var i, tabcontent, tabHeader, tablinks;
          tabcontent = document.getElementsByClassName("tabcontent");
          tabHeader = document.getElementsByClassName("tab-header");
          tablinks = document.getElementsByClassName("tablinks");
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(
              " active",
              ""
            );
          }
          for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
          }
          for (i = 0; i < tabHeader.length; i++) {
            tabHeader[i].style.display = "none";
          }
          document.getElementById(cardId).style.display = "block";
          document.getElementById(cardId + "header").style.display = "flex";
          evt.currentTarget.className += " active";
        }
      }
    },

    SelectMatrix() {
      let major_team_id = [0, 0];
      for (let matrix in this.Matrix) {
        if (this.Matrix[matrix]["team_weight"] > major_team_id[0]) {
          major_team_id[0] = this.Matrix[matrix]["team_weight"];
          major_team_id[1] = this.Matrix[matrix]["team_id"];
        }
      }
      document.getElementById(`matrix${major_team_id[1]}`)?.click();
    },

    async GetPermissions(UserIdState) {
      const response = await KPI.CallBackEnd("get", "get-permissions", {
        params: {
          cycle_id: localStorage.getItem("cycle"),
          userId: UserIdState,
        },
      })
      
      this.permission = response.data
      this.show = true
    },


  },
  computed: {
    ...mapGetters([
      "Matrix",
      "UserIdState",
      "Show",
      "EvaluationType",
      "MatrixEvaluation",
      "GetEvaluationSelected"
    ]),
  },
  mounted() {
    let args;
    if (history.state.userId) {
      args = history.state.userId;
    } else {
      args = isNaN(parseInt(localStorage.getItem("userId"), 10))
        ? null
        : parseInt(localStorage.getItem("userId"), 10);
    }
    // this.StoreUserId(args);
    this.FetchMatrix(args).then((res) => {
      if (res == true) {
        this.noMatrix = true;
      } else {
        this.GetPermissions(this.UserIdState);
      }
    })

    this.cycleFinished = JSON.parse(localStorage.getItem('cycle_finished'))
  },
  updated() {
    let matrix = false
    if (this.rendered === false) {
      if (history.state.teamId)
        matrix = document.getElementById(`matrix${history.state.matrix}`)

      if(matrix)
        matrix.click()
      else
        this.SelectMatrix()
      
      this.rendered = true
    }
  },
  beforeUpdate() {
    localStorage.setItem("userId", this.UserIdState);
  },
  beforeUnmount() {
    localStorage.removeItem("userId");
  },
  beforeRouteLeave() {
    this.ResetMatrix();
  },
};
</script>

<style scoped>
@import '../assets/matrix.css';

.frame {
  padding: 0px 15px 50px 15px;
}

.no-matrix {
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  padding-top: 150px;
  min-height: 250px;
}

.tab {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

.tablinks.active {
  border: 1px solid black;
  border-bottom: 1px solid white;
  opacity: 1 !important;
  background-color: white !important;
}

.tablinks.active .div-left {
  color: var(--default-app-color);
}

.tab-header {
  display: none;
}

.tabcontent {
  display: none;
  border-top: none;
}

.tablinks {
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 6px 8px;
  text-align: center;
  font-weight: bold;
  background-color: var(--medium-gray-color);
  font-size: 100%;
  opacity: 0.4;
  border: 1px solid transparent;
}

.tablinks div {
  align-self: center;
  width: 50%;
}

.tablinks div:nth-child(1) {
  text-align: left;
}

.tablinks div:nth-child(2) {
  text-align: right;
}

.tablinks div p {
  margin: 0;
}

.input {
  width: 32px;
  padding: 2px;
  text-align: center;
}

.input-weight {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end;
  gap: 1px;
  min-width: -webkit-fill-available;
}

.input-weight p {
  margin: auto 0 !important;
}

.header {
  width: 100%;
  height: 120px;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 1011px;
}

.sticky + .second-header {
  padding-top: 110px;
}

.div-left {
  align-self: flex-start !important;
}

.p-weight {
  width: 35px;
  margin-left: 9px;
}

.p-eval {
  width: 50px;
  text-align: end;
  font-weight: normal;
}

@media only screen and (max-width: 1400px) {
  .content {
    font-size: 11px;
  }
  .sticky {
    width: 805px;
  }
  .select-evaluation {
    width: 180px;
  }
}

@media print {
  .header {
    width: 100%;
  }
}
</style>