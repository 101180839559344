<template>
  <div class="container-table">
    <div class="first-table">
      <div>
        <h3>Instruções</h3>
        <ul>
          <li>
            Nome do ficheiro: [ID do indicador]_[data do resultado]. Ex.:
            1005_2021-05-09
          </li>
          <li>O ficheiro Excel deverá conter uma linha de cabeçalho</li>
          <li>
            A ordem das colunas deverá respeitar a ordem do <b>"Exemplo"</b>
          </li>
          <li>Métrica binária: S ou N</li>
          <li>
            Adicione apenas ficheiros ".csv" com codificação UTF-8
            <i
              class="fas fa-info-circle icon cursor-pointer"
              aria-hidden="true"
              @click="openModal($event)"
            ></i>
          </li>
        </ul>
      </div>

      <div>
        <h3>Exemplo</h3>
        <table class="exTable example">
          <thead>
            <tr>
              <th>1. N° interno do utilizador</th>
              <th>2. Resultado</th>
              <th>3. N° da equipa</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Ex.: A001</td>
              <td>Ex.: A020</td>
              <td>Ex.: 35</td>
            </tr>
            <tr>
              <td>(campo alfa-numérico)</td>
              <td>(campo alfa-numérico)</td>
              <td>(campo numérico)</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="tables">
      <div class="input-table">
        <div class="upload-file">
          <div>
            <input
              ref="labelInput"
              class="inputfile cursor-pointer"
              type="text"
              onclick="document.getElementById('input').click()"
              :placeholder="
                files === null
                  ? 'Selecionar ficheiro(s) [ CSV UTF-8 (Comma delimited) (*.csv) ]'
                  : files.length > 1
                  ? files.length + ' arquivos selecionados'
                  : files.length + ' arquivo selecionado'
              "
              readonly
            />
            <input
              class="hidden-input"
              id="input"
              type="file"
              ref="files"
              @change="SelectFile()"
              multiple
            />
          </div>
          <button
            :class="files == null ? 'disabled' : ''"
            @click="Import()"
            :disabled="files == null"
          >
            ADICIONAR
          </button>
        </div>
        <div class="files-table">
          <table class="exTable tb-explorer">
            <tbody>
              <tr v-for="(file, index) in fileList" :key="index" class="tr-explorer">
                <td>
                  <i class="fas fa-file green" aria-hidden="true"></i>
                </td>
                <td>
                  <p class="name cursor-pointer" @click="Download(file)" :ref="'file_' + file">
                    {{ file }}
                  </p>
                </td>
                <td @click="ConfirmDeleteFile(file)">
                  <i
                    :ref="'trash_' + file"
                    class="fas fa-trash red cursor-pointer"
                    aria-hidden="true"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="upload-results">
          <button class="btn-action" @click="ConfirmUploadResults()">INICIAR PROCESSO</button>
        </div>
      </div>
      <div>
        <h3>Histórico</h3>
        <div class="imported scrollbar">
          <table class="exTable fileTable">
            <thead>
              <tr>
                <th>Nome do ficheiro</th>
                <th>Data dos resultados</th>
                <th>Data de importação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(file, index) in importedFileList" :key="index">
                <td>{{ file.filename }}</td>
                <td>{{ file.file_date }}</td>
                <td>{{ file.import_date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <section id="modal" class="modal" :class="{ ativo: modalAtivo }">
    <div class="div-import-help modal-container">
      <button
        class="fas fa-times close-import"
        @click="modalAtivo = false"
      ></button>
      <li>Grave o ficheiro com o seguinte formato:</li>
      <img style="margin-top: 5px" src="../images/excel.png" />
    </div>
  </section>
</template>

<script>
import { KPI } from "../kpi";
import { Gems } from "../gems";

export default {
  name: "Results",
  data() {
    return {
      cycleName: null,
      files: null,
      fileList: [],
      importedFileList: [],
      modalAtivo: false,
    };
  },
  methods: {
    async Download(name) {
      const response = await KPI.CallBackEnd("post", "download-result", {
        params: {
          path: name,
          cycle: localStorage.getItem("cycle"),
          cycle_name: this.cycleName,
        },
      })

      Gems.ExportExcel(response.data)
    },

    clickForaModal({ target, currentTarget }) {
      if (target === currentTarget) this.modalAtivo = false;
    },

    ConfirmDeleteFile(filename) {
      Gems.Telegram(`Apagar ficheiro ${filename}?`, "tele-info", "", "").then(
        () => {
          this.DeleteFile(filename);
        },
        () => {}
      );
    },

    async DeleteFile(filename) {
      const response = await KPI.CallBackEnd("post", "delete-file", {
        cycle: localStorage.getItem("cycle"),
        name: filename,
      })

      if (response.status == 200 && response.data.error == true) 
        Gems.Telegram(response.data.message, "tele-not-allowed")
      
      this.GetFilesList()
    },

    ConfirmUploadResults() {
      Gems.Telegram("Deseja iniciar o processo de carregamento?","tele-info", "", "")
        .then(
          () => { this.UploadResults()},
          () => {}
        );
    },

    async UploadResults() {
      const response = await KPI.CallBackEnd("post", "import-results", {
        cycle: localStorage.getItem("cycle"),
        cycle_name: this.cycleName,
      })

      if(response.error) {
        Gems.Telegram(response.data.message, "tele-not-allowed")
        return
      }
      
      Gems.Telegram("Processo concluído", "tele-success")
      this.GetFilesList()
    },

    SelectFile() {
      this.files = this.$refs.files.files;
    },

    async Import() {
        const formData = new FormData();

        for (let file in this.$refs.files.files) {
          if (typeof this.$refs.files.files[file] == "object") {
            formData.append(file, this.$refs.files.files[file]);
          }
        }
        formData.append("cycle_id", localStorage.getItem("cycle"));
        formData.append("cycle_name", this.cycleName);
        try {
          const response = await KPI.CallBackEnd("post", "import-results-excel", formData)
          if (response.status == 200) {
            if (response.data.error == true) 
              Gems.Telegram(response.data.message, "tele-not-allowed")
            else 
              Gems.Telegram("Ficheiro(s) adicionado(s)", "tele-success")
          }
          this.GetFilesList()
        } catch (err) {
          Gems.Telegram("Contacte o administrador", "tele-not-allowed" )
        }
        this.$refs.files.value = null;
        this.files = null;
    },

    async GetFilesList() {
      await KPI.CallBackEnd("get", "get-files-to-import", {
        params: { id: localStorage.getItem("cycle") },
      }).then((res) => {
        this.cycleName = res.data[0];
        this.fileList = res.data[1];
        this.importedFileList = res.data[2];
      });
    },

    openModal() {
      this.modalAtivo = true;
    },
  },
  mounted() {
    this.GetFilesList();
    Gems.Tooltip(
      this.$refs.labelInput,
      "Selecionar ficheiro [ CSV UTF-8 (Comma delimited) (*.csv) ]",
      "tooltip"
    );
  },
  updated() {
    for (let file in this.fileList) {
      let name = "trash_" + this.fileList[file];
      let fileRef = "file_" + this.fileList[file];

      if (this.$refs[name][0]) {
        Gems.Tooltip(this.$refs[name][0], "Apagar Ficheiro", "tooltip");
      }

      if (this.$refs[fileRef][0]) {
        Gems.Tooltip(this.$refs[fileRef][0], "Fazer download", "tooltip");
      }
    }
  },
};
</script>

<style scoped>
.container-table {
  display: flex;
  flex-direction: column;
  padding: 19px;
}

.tr-explorer {
  vertical-align: top;
}

.container-table h4 {
  margin: 0;
  margin-left: 20px;
}

table.exTable {
  background-color: #ffffff;
  width: 450px;
  text-align: center;
  border-collapse: collapse;
  word-wrap: break-word;
}

table.exTable td,
table.exTable th {
  border: 1px solid #808080;
  padding: 3px 2px;
}

table.exTable tbody td {
  font-size: 12px;
  color: #000000;
}

table.exTable thead {
  background: #c0c0c0;
}

table.exTable thead th {
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  text-align: center;
  border-left: 1px solid #808080;
}

table.exTable tfoot td {
  font-size: 14px;
}

table.exTable tfoot .links {
  text-align: right;
}

table.exTable tfoot .links a {
  display: inline-block;
  background: #1c6ea4;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}

.imported {
  padding: 3px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.first-table {
  display: flex;
  justify-content: space-between;
}

.tables {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}

.tables div:nth-child(2) {
  width: 500px;
}

table.example {
  margin-top: 13px;
}

.tables div h3 {
  margin-bottom: 13px;
}

.tables div:nth-child(2) table.fileTable,
table.example {
  width: 500px;
}

h3 {
  margin: 0;
}

table.tb-explorer {
  border-collapse: collapse;
  box-shadow: inset 0px 0px 10px 0px grey;
  border-radius: 4px;
  height: 120px;
}

table.tb-explorer thead {
  border-bottom: 0px solid #000;
}

table.tb-explorer thead th {
  border-left: 0px solid #000;
}

table.tb-explorer td,
table.tb-explorer th {
  border: 0px solid #000;
}

table.fileTable {
  height: 0px;
}

table.fileTable tbody tr,
table.exTable tbody tr {
  height: 30px;
}

table.fileTable thead th:nth-child(1) {
  width: 45%;
}

.name {
  margin: 0 auto;
  text-align: left;
}

.green {
  color: green;
  font-size: 1.2em;
}

.upload-file {
  width: 450px;
  display: flex;
  margin-bottom: 5px;
}

.upload-file button {
  padding: 0px;
  height: 28px;
  font-size: 13px;
  margin-left: 5px;
}

.inputfile {
  width: 345px;
  text-align: center;
  color: gray;
  background-color: white;
  border: 1px solid var(--medium-gray-color);
}

.hidden-input {
  display: none;
}

.imported {
  height: 625px;
}

.modal {
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: none;
  cursor: default;
  width: 600px;
}

.modal.ativo {
  display: block;
  z-index: 1000;
}

.modal-container {
  top: -180px;
}

.modal-container li {
  margin-left: 0;
}

.upload-results {
  margin-top: 5px;
  width: 450px;
}

.icon {
  font-size: 1.125em;
}

li {
  margin-left: -22px;
}

.fa-trash {
  color: red;
  font-size: 1.2em;
}

.disabled {
  cursor: default;
  opacity: 0.8;
}

.disabled:hover {
  border: 1px solid var(--medium-gray-color);
  color: var(--font-color);
}

@media only screen and (max-width: 1400px) {
  table.exTable,
  table.fileTable,
  .tables div:nth-child(2),
  .tables div,
  .input-table {
    width: 390px !important;
  }

  .inputfile {
    width: 280px !important;
  }

  .modal {
    left: 58%;
  }
}
</style>
